import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bitclashToUstc, bitclashToEth, formatNumber } from '../../Helpers/ConversionApi';
import { getAmountOfBitclashAccess, reserveBitClash } from './ReserveBitclashSlice'
import WaitButton from "../../../Elements/WaitButton/WaitButton";

const AmountOfBitclash = (props) => {
    
    const minAmount = useSelector((state) => state.reservecoins.minAmount)
    const maxAmount = useSelector((state) => state.reservecoins.maxAmount)

    const [isActive, setIsActive] = React.useState([false, false, true, false, false, false, false, false]);

    const Amounts = [1250, 2500, 6250, 12500, 25000, 62500, 125000, 250000];
    const NewAmounts = [1250, 2500, 6250, 12500, 25000, 62500, 125000, 250000];
    const AmountsUsdc = [50, 100, 250, 500, 1000, 2500, 5000, 10000];

    const [amount, setAmount] = React.useState(Amounts[2]);

    const[processing, setProcessing] = React.useState(false);

    const dispatch = useDispatch();

    const handleSubmit = () => {
        setProcessing(true)
        dispatch(reserveBitClash(amount, props, () => setProcessing()))
    }

    useEffect(() => {
        dispatch(getAmountOfBitclashAccess());
    }, [])

    const handleBtnClick = id => {
        // 👇️ toggle isActive state on click
        const newState = [false, false, false, false, false, false, false, false]
        newState[id] = true;

        setIsActive(newState);

        setAmount(Amounts[id]);
    };

    return (
        <>
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-amountOfBitClash">
                            <div className="CC-cardBox">
                                <div className="CC-cardBox__header">
                                    <div className="CC-cardBox__headerTitle">Amount of Coin Clash Coins</div>
                                </div>
                                <div className="CC-cardBox__body">
                                    <div className="CC-cardBox__bodyDesc">
                                        How much Coin Clash Coins do you wish to buy?
                                    </div>
                                    <div className="tierselction">
                                        <button className={isActive[0] ? "tier selected" : "tier"} onClick={() => handleBtnClick(0)}><span>{formatNumber(NewAmounts[0])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[0])}</span>USDC {formatNumber(AmountsUsdc[0])}</button>
                                        <button className={isActive[1] ? "tier selected" : "tier"} onClick={() => handleBtnClick(1)}><span>{formatNumber(NewAmounts[1])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[1])}</span>USDC {formatNumber(AmountsUsdc[1])}</button>
                                        <button className={isActive[2] ? "tier selected" : "tier"} onClick={() => handleBtnClick(2)}><span>{formatNumber(NewAmounts[2])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[2])}</span>USDC {formatNumber(AmountsUsdc[2])}</button>
                                        <button className={isActive[3] ? "tier selected" : "tier"} onClick={() => handleBtnClick(3)}><span>{formatNumber(NewAmounts[3])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[3])}</span>USDC {formatNumber(AmountsUsdc[3])}</button>
                                    </div>
                                    <div className="tierselction">
                                        <button className={isActive[4] ? "tier selected" : "tier"} onClick={() => handleBtnClick(4)}><span>{formatNumber(NewAmounts[4])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[4])}</span>USDC {formatNumber(AmountsUsdc[4])}</button>
                                        <button className={isActive[5] ? "tier selected" : "tier"} onClick={() => handleBtnClick(5)}><span>{formatNumber(NewAmounts[5])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[5])}</span>USDC {formatNumber(AmountsUsdc[5])}</button>
                                        <button className={isActive[6] ? "tier selected" : "tier"} onClick={() => handleBtnClick(6)}><span>{formatNumber(NewAmounts[6])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[6])}</span>USDC {formatNumber(AmountsUsdc[6])}</button>
                                        <button className={isActive[7] ? "tier selected" : "tier"} onClick={() => handleBtnClick(7)}><span>{formatNumber(NewAmounts[7])}</span><br/><br/><span className="oldPrice">{formatNumber(Amounts[7])}</span>USDC {formatNumber(AmountsUsdc[7])}</button>
                                    </div>
                                    <div>
                                        To pay: &nbsp;
                                            <span className="CC-txtHighlight-secondary">{bitclashToEth(amount / props.amountEth)} ETH</span> or  &nbsp;
                                            <span className="CC-txtHighlight-secondary">{bitclashToUstc(amount)} USDC</span>
                                    </div>
                                </div>
                                <div className="CC-cardBox__footer">
                                    <WaitButton btnclick={handleSubmit} processing={processing} text="Proceed" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AmountOfBitclash