import axios from "axios"

export const bitclashRate = 0.04;

export const conversionApi = (setAmount) => {
    axios.get(`https://api.coinbase.com/v2/prices/ETH-USD/buy`)
        .then(res => {
            setAmount(res.data.data.amount)
        })
        .catch(err => console.log('error', err))
}

export const conversionApiBTC = (setAmount) => {
    axios.get(`https://api.coinbase.com/v2/prices/BTC-USD/buy`)
        .then(res => {
            setAmount(res.data.data.amount)
        })
        .catch(err => console.log('error', err))
}

export const conversionApiBNB = (setAmount) => {
    axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`)
        .then(res => {
            setAmount(res.data.price)
        })
        .catch(err => console.log('error', err))
}

export const formatUS = (money) =>{
    return  money.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
  }

export const bitclashToUstc = (amount) =>{
    const convertedAmount = formatUS(amount * bitclashRate);
    return convertedAmount
}

export const formatNumber = (amount) =>{
    const convertedAmount = parseFloat(amount);
    return convertedAmount.toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2 })
}

export const bitclashToEth = (amount) =>{
    const convertedAmount = amount  * bitclashRate
    return convertedAmount.toFixed(4)
}

export const ethToBitClash = (amount, amountEth) =>{
    const convertedAmount = amount * amountEth / bitclashRate
    return convertedAmount.toFixed(2)
} 

export const bitclashToBTC = (amount, BTCtoUSD) =>{
    const convertedAmount = amount  * bitclashRate / BTCtoUSD
    return convertedAmount.toFixed(8)
}

export const bitclashToBNB = (amount, BNBtoUSD) =>{
    const convertedAmount = amount  * bitclashRate / BNBtoUSD
    return convertedAmount.toFixed(4)
}