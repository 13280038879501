import { authApi } from "../EndPointsConfig/EndPointConfig";
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../Utils/apiCaller";

export const RegisterSlice = createSlice({
    name: "registerSlice",
    initialState: {
        userData: [],
        errorMessage: "",
        token: false,
        message:"",
    },
    reducers: {
        setRegister: (state, action) => {
            state.userData = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
    }
})

export const { setRegister, setToken, setMessage } = RegisterSlice.actions;

export const getRegisterAccess = (params, props, processing) => (dispatch) => {
    let obj = {
        email:params.email,
        password:params.password,
        referrer:params.referrer,
    };

    console.log(obj)
    console.log(params)

    let url = `${authApi.authEndPoint}`+"/signup";
    apiCaller(url, "POST", obj)
        .then((response) => {
            processing(false);
            if(response !== null && response) {
                if(response.status === 1){
                    localStorage.setItem("token", response.token);
                    dispatch(setRegister(response.email));
                    props.handleEmailChange(params.email)
                    props.history.push("/verifyemail");
                }else{
                    dispatch(setMessage(response.message));
                    params.handleError();
                }
            }
        })
        .catch((error) => {
            dispatch(setMessage(error.error))
            processing(false);
        });
}

export default RegisterSlice.reducer;