import React from 'react'
import OTPInput from "otp-input-react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getVerifyEmailAccess, getResendOtpAccess } from './VerifyEmailSlice';
import WaitButton from "../../../Elements/WaitButton/WaitButton";

const VerifyEmail = (props) => {

    const [OTP, setOTP] = React.useState("");
    const [otpError, setOtpError] = React.useState(false);
    const [resendSuccess, setResendSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");

    const[processing, setProcessing] = React.useState(false);
    const errorMessage = useSelector((state) => state.verifyemail.message)

    const dispatch = useDispatch();
    const handleSubmit = () => {
        if (OTP.length >= 6) {
            setProcessing(true)
            dispatch(getVerifyEmailAccess({ otp: OTP }, props, () => setProcessing()))
        } else {
            setOtpError(true)
            setTimeout(() => {
                setOtpError(false)
            },5000)
        }
    }
    const handleResend = () =>{
        dispatch(getResendOtpAccess({resend : true},props))
        setResendSuccess(true)
        setSuccessMessage("OTP sent succesfully")
        setTimeout(() => {
            setResendSuccess(false)
            setSuccessMessage("")
        },5000)
    }
    return (
        <>
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-verifyEmail">
                            <div className="CC-cardBox">
                                <div className="CC-cardBox__header">
                                    <div className="CC-cardBox__headerTitle">Verify Email</div>
                                </div>
                                <div className="CC-cardBox__body">
                                    <div className="CC-cardBox__bodyDesc">
                                        We have sent email to <span
                                            className="CC-txtHighlight-secondary">{props.email}</span><br />
                                        Please enter the verification code sent to your mail id
                                    </div>
                                    <div className="CC-form-group OTP-form-Group">
                                        <div className="CC-form-control CC-form-control-group">
                                            <OTPInput
                                                value={OTP}
                                                onChange={setOTP}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    
                                    {resendSuccess ? (
                                        <p className='CC-form-successMsg'>{successMessage}</p>
                                    ): null}

                                    {otpError ? (
                                        <p className='CC-form-errorMsg'>{props.message}</p>
                                    ) : null}

                                    {errorMessage !== "" ? (
                                        <p className='CC-form-errorMsg'>{errorMessage}</p>
                                    ) : null}

                                    <WaitButton btnclick={handleSubmit} processing={processing} text="Verify" />
                                    {/* <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"
                                        onClick={() => handleSubmit()}>Verify
                                        email</button> */}
                                </div>
                                <div className="CC-cardBox__footer">
                                    <div className="CC-cardBox__footerInfo">You did not recieve our email? Click here to <a
                                        onClick={() => handleResend()}>resend</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        opt: state.verifyemail.opt,
        message: state.verifyemail.message,
        otpResend: state.verifyemail.otpResend,
        successMessage: state.verifyemail.successMessage
    }
}

export default connect(mapStateToProps)(VerifyEmail);
