import React from 'react'
// import successIcon from '../../../assets/images/other-icons/barcode.svg';
import closeIcon from '../../../assets/images/other-icons/close.svg';
import { connect, useDispatch } from 'react-redux'
import { buySuccess } from './BuyCoinNotificationSlice';

const BuyCoinNotification = (props) => {
    const dispatch = useDispatch();
    const handleBuyMore = () =>{
        dispatch(buySuccess(props.amount, props));
    }
    React.useEffect(() => {
        props.handleBuySucess()
    },[])
  return (
    <>
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-success">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__header">
                                <div className="CC-cardBox__headerTitle">Coin Seed Sale</div>
                                <div className="CC-cardBoxClose">
                                    <div onClick={() => props.history.push("/dashboard")}><img alt="" src={closeIcon} /></div>
                                </div>
                            </div>
                            <div className="CC-cardBox__body">
                                <div className="CC-cardBox__bodyDesc">We are constantly monititoring all out wallets. Once you make a transaction it might take up to 48 hours until your Coin Clash Coins balance is updated. If you want to speed it up, please send us the link to transaction on etherscan to <a href="mailto:tokensale@coin-clash.com">tokensale@coin-clash.com</a>.</div>
                                {/* <div className="CC-successImg">
                                    <img src={successIcon} alt="Email Verification" />
                                </div> */}
                                <div className="CC-space20"></div>
                            </div>
                            <div className="CC-cardBox__footer">
                                <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"  onClick={() => handleBuyMore() }
                                >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BuyCoinNotification
