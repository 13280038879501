import React, { useState } from 'react'
import { ethers } from 'ethers'
import { v4 as uuid } from 'uuid';
import { connect, useDispatch, useSelector } from "react-redux";
import { verifyEth } from './VerifyEthSlice'
import WaitButton from "../../../Elements/WaitButton/WaitButton";


const VerifyEth = (props) => {
    const [verified, setVerified] = useState(false);
    const [address, setAddress] = useState("");
    const [nonce, setNonce] = useState("");
    const [signature, setSignature] = useState("");
    const [inputErrMsg, setInputErrMsg] = useState('');
    const [metaMaskMsg, setMetaMaskMsg] = useState('');
    const [metaMaskMsgErrMsg, setmetaMaskErrMsg] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    const [isActive, setIsActive] = React.useState([true, false, false, false]);
    const [chain, setChain] = useState('ETH - ');

    const dispatch = useDispatch();

    const[processing, setProcessing] = React.useState(false);


    const handleAuthorizeMetaMask = async () => {
        setMetaMaskMsg("")
        if (window.ethereum && window.ethereum.isMetaMask) {
            try {
                const accounts = await window.ethereum.request({ method: "eth_accounts" });
                (accounts.length === 0 && await window.ethereum.request({ method: "eth_requestAccounts" }));
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const addres = await signer.getAddress();
                const nonce = "Authorize for use on Coin Clash";//uuid();
                const signature = await signer.signMessage(nonce);
                setVerified(true);
                setAddress(addres);
                setNonce(nonce);
                setSignature(signature);
                setInputErrMsg("");
            } catch (e) {
                if (e.code === 4001) {
                    const msg = "user declined the metamask request"
                    setInputErrMsg(msg)
                }
                else {
                    // toast.error("Something Went Wrong");
                }
            }
        } else {
            setIsEditable(true)

        }


    }

    const setHandleManualInput= (e) => {
        setAddress(e.target.value)
    }

    const handleProcess = () => {
        const addressSend = chain + address;

        console.log(addressSend)

        if (address.length === 0 && chain !== "EXCHANGE - ") {
            setmetaMaskErrMsg(true)
            setMetaMaskMsg("Please provide the wallet address")
        } else {
            console.log(addressSend)
            setProcessing(true)
            dispatch(verifyEth({ addressSend, nonce, signature }, props, () => setProcessing()));
        }
    }


    const handleBtnClick = id => {
        // 👇️ toggle isActive state on click
        const newState = [false, false, false, false]
        newState[id] = true;

        switch(id) {
            case 0:
                setAddress("");
                setVerified(false);
                setChain('ETH - ');
                break;
            case 1:
                setAddress("");
                setVerified(false);
                setChain('BTC - ');
                break;
            case 2:
                setAddress("");
                setVerified(false);
                setChain('BNB - ');
                break;
            case 3:
                setAddress("");
                setVerified(false);
                setChain('EXCHANGE - ');
                break;
            case 4:
                setAddress("");
                setVerified(false);
                setChain('MAT - ');
                break;
            default:
                setChain('ETH - ');
                break;
          }

        setIsActive(newState);
    };

    return (
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-verifyCoinAddress">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__header">
                                <div className="CC-cardBox__headerTitle">Your wallet address</div>
                            </div>
                            <div className="CC-cardBox__body">
                                <div className="CC-cardBox__bodyDesc">
                                    Please provide us with your wallet address from where you will be transfering the funds.
                                </div>
                                <div className="CC-form-group">
                                    <div className="CC-form-control">
                                        <input type="text" value={address}  onChange={(e) => setHandleManualInput(e)} placeholder="Wallet address" />
                                    </div>
                                </div>
                                {inputErrMsg !== "" && <p className="CC-form-errorMsg">{inputErrMsg}</p>}
                                {metaMaskMsgErrMsg && <p className="CC-form-errorMsg">{metaMaskMsg}</p>}

                                <div className="tierselction">
                                    <button className={isActive[0] ? "tier selected" : "tier"} onClick={() => handleBtnClick(0)}><span>ETH chain</span></button>
                                    <button className={isActive[1] ? "tier selected" : "tier"} onClick={() => handleBtnClick(1)}><span>BTC chain</span></button>
                                    <button className={isActive[2] ? "tier selected" : "tier"} onClick={() => handleBtnClick(2)}><span>BNB chain</span></button>
                                    <button className={isActive[4] ? "tier selected" : "tier"} onClick={() => handleBtnClick(4)}><span>POLYGON</span></button>
                                    <button className={isActive[3] ? "tier selected" : "tier"} onClick={() => handleBtnClick(3)}><span>Exchange</span></button>
                                </div>

                                {window.ethereum && window.ethereum.isMetaMask && (isActive[0] || isActive[2] || isActive[4]) && !verified ? 
                                    <>
                                        <div className="CC-cardBox__bodyDesc">
                                            Please authorise your address with <span className="CC-txtHighlight-primary">metamask</span>
                                        </div>
                                        <div className="CC-form-group">
                                            <button className="btn CC-btnSecondary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleAuthorizeMetaMask()}>Authorise
                                                Metamask</button>
                                        </div>
                                    </>
                                    : <>
                                        <div className="CC-form-group"></div>
                                    </>
                                }

                                <div className="CC-cardBox__footer">
                                    <WaitButton btnclick={handleProcess} processing={processing} text="Proceed" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        message: state.verifyETH.message,
        successMessage: state.verifyETH.successMessage,
    }
}

export default connect(mapStateToProps)(VerifyEth);