
import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../Utils/apiCaller";
import { accountVerificationStatus } from "../EndPointsConfig/EndPointConfig";

export const AccountVerificationSlice = createSlice({
    name: "accountVerificationslice",
    initialState: {
        message:"",
        statusMessage: "",
        accountVerified:false,
        stagestatus: "",
        userCoins: 0,
        userEarning: 0,
    },
    reducers:{
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setAccountVerified: (state, action) => {
            state.accountVerified = action.payload;
        },
        setStatusMessage: (state, action) =>{
            state.statusMessage = action.payload;
        },
        setStageStatus: (state, action) =>{
            state.stagestatus = action.payload;
        },
        setUserCoins: (state, action) =>{
            state.userCoins = action.payload;
        },
        setUserEarning: (state, action) =>{
            state.userEarning = action.payload;
        }
    }
})

export const {setMessage, setAccountVerified, setStatusMessage,setStageStatus,setUserCoins,setUserEarning} = AccountVerificationSlice.actions;

export const getAccountverificationStatus =  () => (dispatch) => {
    
    let url = `${accountVerificationStatus.accountStatus}`;
    apiCaller(url, "GET", {})
    .then((response) => {
        if (response !== null && response) {
          if (response.status === 1) {
            dispatch(setStatusMessage(response.verified_status))
            dispatch(setAccountVerified(response.verified))
            dispatch(setStageStatus(response.verification_stage))
          } else {
            dispatch(setMessage(response.message));
          }
        }
      })
      .catch((error) => dispatch(setMessage(error.message)));
}

export const getAccountCoins =  () => (dispatch) => {
    let url = `${accountVerificationStatus.usercoins}`;
    apiCaller(url, "GET", {})
    .then((response) => {
        if (response !== null && response) {
          if (response.status === 1) {
            dispatch(setUserCoins(response.clashcoin))
          } else {
            dispatch(setMessage(response.message));
          }
        }
      })
      .catch((error) => dispatch(setMessage(error.message)));
}

export const getAccountEarnings =  () => (dispatch) => {
    let url = `${accountVerificationStatus.stakeearn}`;
    apiCaller(url, "GET", {})
    .then((response) => {
        if (response !== null && response) {
          if (response.status === 1) {
            dispatch(setUserEarning(response.earn))
          } else {
            dispatch(setMessage(response.message));
          }
        }
      })
      .catch((error) => dispatch(setMessage(error.message)));
}

export default AccountVerificationSlice.reducer;
