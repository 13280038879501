
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChangePassword } from './ChangePasswordSlice';
import WaitButton from "../../../Elements/WaitButton/WaitButton";

const ChangePassword = (props) => {
    const [emailForgot, setEmailForgot] = React.useState("");
    const [emailError, setEmailError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const successMsg = useSelector(state => state.changePassword.successMsg);
    const message = useSelector(state => state.changePassword.message);

    const[processing, setProcessing] = React.useState(false);

    const dispatch = useDispatch('');

    const emailChange = (e) => {
        setEmailForgot(e.target.value)
    }

    const onSubmit = () => {
        const reg = /.+@.+\.[A-Za-z]+$/;
        if (emailForgot === "") {
            setEmailError(true)
            setErrorMsg("Field cannot be empty")
        } else if (!reg.test(emailForgot)) {
            setEmailError(true)
            setErrorMsg("Invalid email address")
        } else {
            setProcessing(true)
            dispatch(getChangePassword({ emailForgot }, props, () => setProcessing()))
            setEmailError(false);
         
        }
    }

    useEffect(()=>{
        console.log(successMsg)
        successMsg !== "" && setTimeout(()=>{
            props.history.push('/login');
        },2000)
    },[successMsg])


    return (
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-login">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__header mb-20">
                                <div className="CC-cardBox__headerTitle">Forgot Password?</div>
                            </div>
                            <div className='CC-cardBox__headerInfo'>
                                Back to login?
                                <a href='/login'>
                                    <span className="CC-txtHighlight-secondary">  Login</span>
                                </a>
                            </div>
                            <div className="CC-cardBox__body">
                                <div className="CC-form-group">
                                    <div className="CC-form-control">
                                        <input type="email" placeholder="Email"
                                            value={emailForgot}
                                            onChange={(e) => emailChange(e)} />
                                    </div>
                                    {emailError ? (
                                        <p className="CC-form-errorMsg">{errorMsg}</p>
                                    ) : null}
                                </div>
                                {message !== "" && <p className="CC-form-errorMsg">{message}</p>}
                                {successMsg !== "" && <div className="CC-form-successMsg">{successMsg}</div>}
                            </div>
                            <div className="CC-cardBox__footer">
                                <WaitButton btnclick={onSubmit} processing={processing} text="Submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default ChangePassword





