import { authApi } from "../../EndPointsConfig/EndPointConfig";
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";

export const VerifyEmailSlice = createSlice({ 
    name: "verifyEmailSlice",
    initialState: {
        otp:"",
        message:"",
        otpResend:false,
        successMessage:"",
    },
    reducers:{
        setOtp: (state, action) => {
            state.otp = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setOtpResend: (state, action) => {  
            state.otpResend = action.payload;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        }
    }
});

export const { setOtp, setMessage, setOtpResend, setSuccessMessage } = VerifyEmailSlice.actions;

export const getVerifyEmailAccess = (params, props, processing) => (dispatch) => {
    let obj = {
        otp:params.otp,
    };

    let url = `${authApi.verifyEmailEndPoint}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            processing(false);
            if(response !== null && response) {
                if(response.status === 1){
                    dispatch(setSuccessMessage(response.message))
                    props.history.push("/verifiedemail")
                }else{
                    dispatch(setMessage(response.message));
                }
            }else{
                dispatch(setMessage("Error, please try again"));
            }
        })
        .catch((error) => {
            processing(false);
            dispatch(setMessage(error.error))
        });
}

export const getResendOtpAccess = (props) => (dispatch) => {
    let url = `${authApi.resendOtpEndPoint}`;
    apiCaller(url, "POST", {})
        .then((response) => {
            if(response !== null && response) {
                if(response.status === 0){
                    setOtpResend(props.resend)
                    setSuccessMessage(response.message)
                }else{
                    dispatch(setMessage(response.error));
                }
            }
        })
        .catch((error) => dispatch(setMessage(error.error)));
}

export default VerifyEmailSlice.reducer;