import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../../Utils/apiCaller";
import { changePassword } from "../../EndPointsConfig/EndPointConfig";



const ChangePasswordSlice = createSlice({
    name: "changePasswordSlice",
    initialState: {
        emailId : false,
        message: "",
        successMsg : "",
    },
    reducers: {
        setEmail: (state, action) => {   
            state.emailId = action.payload;
        },
        setError: (state, action) => {  
            state.message = action.payload;
        },
        setSuccessMsg: (state,action) =>{
            state.successMsg = action.payload;
        }
    }
});

export const { setEmail, setError, setSuccessMsg } = ChangePasswordSlice.actions;

export const getChangePassword = (params, props, processing) => (dispatch) => {

    let obj = {
        email: params.emailForgot,
    };

    let url =  changePassword.changePasswordEndPoint;
    apiCaller(url, "POST", obj)
        .then((response) => {
            processing(false);
            if(response !== null && response) {
                if(response.status == 1){
                    dispatch(setEmail(true));
                    dispatch(setSuccessMsg(response.message));
                    dispatch(setError(""))
                }
                if(response.status == -1){
                    dispatch(setError(response.message));
                    dispatch(setSuccessMsg(""));
                }
            }
        })
        .catch((error) => {
            dispatch(setError(error.message));
            processing(false);
        });
}

export default ChangePasswordSlice.reducer;