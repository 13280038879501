import React from "react";
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/coin-clash-logo.png'

const Header = (props) => {

    const handleLogoutClick = () => {
        props.handleLogoutSuccess()
    }

    return (
        <>
                <div className="CC-mainHeader">
                    <div className="CC-container">
                        <div className="CC-mainHeader__container">
                            <div className="CC-mainHeader-left">
                                <div className="CC-brandLogo">
                                    <Link to={props.authorization ? "/dashboard" : "/login"}>
                                        <img src={Logo} alt="Coin Clash Logo"/>
                                    </Link>
                                </div>
                            </div>
                            {props.authorization && (
                                <div className="CC-mainHeader-right">
                                    <Link to="/login">
                                        <button className="btn CC-btnPrimary active CC-btnLarge CC-btnLogout" onClick={() => handleLogoutClick()}>Logout</button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Header