import React from 'react'
import image from '../../../assets/images/other-icons/email-verified.png'

const VerifiedEmail = (props) => {
    const handleClose = () => {
        localStorage.removeItem("token");
        props.history.push("/login")
    }

    return (
        <>
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-verifiedEmail">
                            <div className="CC-cardBox">
                                <div className="CC-cardBox__header">
                                    <div className="CC-cardBox__headerTitle">Verify Email</div>
                                </div>
                                <div className="CC-cardBox__body">
                                    <div className="CC-cardBox__bodyDesc">
                                        Thank you for verifying your email
                                    </div>
                                    <img src={image} alt="Email Verification" />
                                    <div>Email Verified!!</div>
                                    <div className="CC-space20"></div>
                                </div>
                                <div className="CC-cardBox__footer">
                                    <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"
                                        onClick={() => handleClose()}
                                    >Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifiedEmail
