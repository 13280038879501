
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";
import { mobileNumApi, accountVerificationStatus } from "../../EndPointsConfig/EndPointConfig";

export const FinishedMobileNumberSlice = createSlice({
    name: "mobileNumberSlice",
    initialState: {
        message:"",
        successMessage: "",
        accountVerified:false,
    },
    reducers:{
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setAccountVerified: (state, action) => {
            state.accountVerified = action.payload;
        },
        setSuccesMessage: (state, action) =>{
            state.successMessage = action.payload;
        }
    }
})

export const {setMessage, setAccountVerified, setSuccesMessage} = FinishedMobileNumberSlice.actions;

export const getMobileNumberAccess =  (params, props) => (dispatch) => {
    let obj = {
        mobileNumber: params.mobileNumber,
    };

    let url = `${mobileNumApi.mobilenumEndPoint}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
                 if(response.errorCode){
                    dispatch(setMessage(response.message))
                    dispatch(setSuccesMessage(''));

                }else {
                    dispatch(setMessage(''))
                    dispatch(setAccountVerified(response.accountVerified))
                    dispatch(setSuccesMessage(response.message));
                }
            
        })
        .catch((response) => dispatch(setMessage(response.message)));
}

export const setStatusFailed =  (params, props) => (dispatch) => {
    let obj = {
        stage: 2,
    };

    let url = `${accountVerificationStatus.statusSet}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            console.log(response)
        })
        .catch((response) => dispatch(setMessage("")));
}

export default FinishedMobileNumberSlice.reducer;
