import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import {getMobileNumberAccess, setStatusSuccess} from './FinishedMobileNumberSlice'


const FinishedMobileNumber = (props) => {

const [ mobileNumber, setMobileNumber] = React.useState('');
const [err, setErr] = React.useState(false)
const dispatch = useDispatch();


const handleSubmit = () => {
    const num = mobileNumber.length > 5 ? mobileNumber : ''
    dispatch(getMobileNumberAccess({mobileNumber :num}, props));
    if(mobileNumber < 6){
      setErr(true)
    }else{
        setErr(false)
        props.verifyAccountSuccess()
    }
    
}

useEffect(()=>{
    dispatch(setStatusSuccess())
},[])

useEffect(()=>{
    // props.successMessage !== "" && setTimeout(()=> props.history.push('/dashboard'),2000)
},[props.successMessage])

  return (
   
      <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-finished">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__header">
                                <div className="CC-cardBox__headerTitle">You are now verified</div>
                            </div>
                            <div className="CC-cardBox__body">
                                <p>
                                    Thank you for finishing the verification process. You are now verified and can proceed with the purchase of tokens.
                                </p>
                                <div className="CC-space20"></div>
                                
                            </div>
                         
                            <div className="CC-cardBox__footer">
                                <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"
                                 onClick={() => props.history.push("/dashboard")}
                                    >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
  )
}
const mapStateToProps = (state) =>{
    return{
        message: state.finishedMobileNumber.message,
        successMessage: state.finishedMobileNumber.successMessage,
    }
}

export default connect(mapStateToProps)(FinishedMobileNumber)

