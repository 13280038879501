import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { getSubscription } from './SubscriptionSlice';

const Subscription = (props) => {

    const token = localStorage.getItem("token");

    const parseJwt = (token) => {
        try {
        return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
        return null;
        }
    };

    const decodedJwt = parseJwt(token);

    const [email, setEmail] = React.useState(decodedJwt.email);

    const dispatch = useDispatch();
    // const handleEmail = (e) => {
    //     setEmail(e.target.value)
    // } 
    const submitForm = (e) => {
        e.preventDefault();
        dispatch(getSubscription({ email }, props))
    }
    return (
        <>
                    <div className="CC-section CC-subscribe">
                        <div className="CC-container">
                            <div className="CC-section__content">
                                <div className="CC-sectionHeading">Be the first to know about <b>Coin Clash</b> news</div>
                                <div className="CC-sectionDesc">Get Coin Clash news and updates right to your inbox! Sign up
                                    here so you don't miss a single newsletter.</div>
                                
                                    <button className="btn btnRect CC-btnPrimary active CC-btnLarge CC-btnSubscribeNow"
                                        type="submit" onClick={(e) => submitForm(e)}>
                                        Subscribe Now
                                    </button>

                                {/* <form className="CC-subscribeNow">
                                    <div className="CC-form-control">
                                        <input type="email"
                                            placeholder="Enter your email address"
                                            value={email}
                                            onChange={(e) => handleEmail(e)}
                                            autoComplete='off' />
                                    </div>
                                    <button className="btn btnRect CC-btnPrimary active CC-btnLarge CC-btnSubscribeNow"
                                        type="submit" onClick={(e) => submitForm(e)}>
                                        Subscribe Now
                                    </button>
                                </form> */}
                            </div>
                            <p className={props.error != '' ? "CC-form-errorMsg":undefined || props.successMsg != '' ? "CC-form-successMsg":undefined}>
                                { props.error != "" ? props.error: "" }
                                { props.successMsg != "" ? props.successMsg : ""}
                            </p>
                        </div>
                    </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.subscription.error,
        successMsg: state.subscription.successMsg
    }
}

export default connect(mapStateToProps)(Subscription);

