
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";
import { mobileNumApi, userApi } from "../../EndPointsConfig/EndPointConfig";
import {amountOfBitClash} from "../../EndPointsConfig/EndPointConfig";

export const BuyCoinClashSlice = createSlice({
    name: "buycoinclashSlice",
    initialState: {
        message:"",
        successMessage: "",
        chain:"ETH",
        minAmount:0,
        maxAmount:10000000,
    },
    reducers:{
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setSuccesMessage: (state, action) =>{
            state.successMessage = action.payload;
        },
        setChain: (state, action) => {
            state.chain = action.payload;
        },
        setMinAmount: (state, action) => {
            state.minAmount = action.payload;
        },
        setMaxAmount: (state, action) => {
            state.maxAmount = action.payload;
        }
    }
})

export const {setMessage, setSuccesMessage, setMinAmount, setMaxAmount, setChain} = BuyCoinClashSlice.actions;

export const getBuyLimits =  (params, props) => (dispatch) => {
    let url = `${amountOfBitClash.amountBuy}`;
    apiCaller(url,"GET")
        .then((response) => {
            console.log(response);
                 if(response.errorCode){
                    dispatch(setMessage(response.message))
                    dispatch(setSuccesMessage(''))
                    dispatch(setMinAmount(50000))
                    dispatch(setMaxAmount(50000));

                }else {
                    dispatch(setMessage(''))
                    dispatch(setSuccesMessage(response.message))
                    dispatch(setChain(response.chain));
                    dispatch(setMinAmount(response.min));
                    dispatch(setMaxAmount(response.max));

                }
            
        })
        .catch((response) => dispatch(setMessage(response.message)));
}

export const updateTxnHash =  (params, props) => (dispatch) => {
    let obj = {
        txhash:params.txnHash,
        address:params.address,
        coinclashcoins:params.bitclashAmount,
    };
    console.log("dispatch is running",obj);//remove this line
    let url = `${userApi.transactionStart}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            console.log(response); //remove this line
            if(response !== null && response) {
                if(response.status !== -1){
                    dispatch(setSuccesMessage(response.message));
                    dispatch(setMessage(""));
                   
                }else{
                    dispatch(setMessage(response.message));
                    dispatch(setSuccesMessage(""));
                }
            }
        })
        .catch((error) => dispatch(setMessage(error.error)));
}

export default BuyCoinClashSlice.reducer;
