import React from 'react'
import telegram from '../../assets/images/social-icons/telegram.svg'
import twitter from '../../assets/images/social-icons/twitter.svg'
import discord from '../../assets/images/social-icons/discord.svg'

const Footer = () => {
    return (
        <>
                <div className="CC-container">
                    <div className="CC-footer-container">
                        <div className="CC-footer-content">
                            <div className="CC-footer-linksList">
                                <img className="CC-company-logo" src="https://html.sportsit-tech.net/coinclash/images/logo-footer.png" />
                                <p>
                                    www.coin-clash.com is operated by MicroClash Ltd,<br />
                                    6.20 World Trade Center, Gibraltar
                                </p>
                                <div className="CC-footerLinks-title">Follow Us</div>
                                <ul className="CC-social-links">
                                    <li>
                                        <a href="https://t.me/coin_clash" target="_blank">
                                            <img
                                                src={telegram}
                                                alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/coin_clash" target="_blank">
                                            <img
                                                src={twitter}
                                                alt="" srcSet="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://discord.gg/MdFqSRcVTw" target="_blank">
                                            <img src={discord}
                                                alt="" />
                                        </a></li>
                                </ul>
                            </div>
                            <div className="CC-footer-linksList">
                                <div className="CC-footerLinks-title">Resources</div>
                                <ul>
                                    <li><a href="https://coinclash.games/privacypolicy.html" target="_blank">Privacy Policy</a></li>
                                    <li><a href="https://coinclash.games/terms.html" target="_blank">Terms of Services</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="CC-footer-copyrights">
                    <div className="CC-container">
                        <div className="CC-footer-copyrights-content">
                            <div className="CC-footer-copyrightsLeft">
                                © 2022 CoinClash. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Footer