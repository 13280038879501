import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from "react-redux";
import { getLoginAccess } from "./LoginSlice";
import WaitButton from "../../Elements/WaitButton/WaitButton";


const Login = (props) => {
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [emailError, setEmailError] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("")
    const [checkboxSave, setCheckboxSave] = React.useState(false)
    const[error, setError] = React.useState(false);
    const dispatch = useDispatch();

    const[processing, setProcessing] = React.useState(false);

    const errorMessage = useSelector((state) => state.login.errorMessage)

    // useEffect(()=>{
    //     if(localStorage.getItem('email') || localStorage.getItem('verifiedAccount' === true) ){
    //         props.verifyAccountSuccess()
    //         props.history.push('/dashboard');
    //     }
    // },[])

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleCheckBox = () => {
        setCheckboxSave(!checkboxSave)
        if(email !== ""){
            props.handleKeepMeSignedIn(email)
        }
    }

    const onSubmit = () => {
        const reg = /.+@.+\.[A-Za-z]+$/;
        setError(false)
        setErrorMsg("")
        if (email === "" || password === "") {
            setEmailError(true)
            setPasswordError(true)
            setErrorMsg("Fields cannot be empty")
        } else if (password.length < 8) {
            setEmailError(false)
            setPasswordError(true)
            setErrorMsg("Pasword should be greater than 8")
        } else if (!reg.test(email)) {
            setEmailError(true)
            setPasswordError(false)
            setErrorMsg("Please enter a valid email address")
        } else {
            setEmailError(false)
            setPasswordError(false)
            props.handleEmailChange(email)
            setProcessing(true)
            dispatch(getLoginAccess({ email, password }, props, () => setProcessing()))
        }
        if(props.message !== ""){
            setError(true)
            setErrorMsg(props.message)
        }
    }


    return (
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-login stretch">
                            <div className="CC-cardBox CC-rowelem-left">
                                <div className="CC-cardBox__header CC-sectionHeading">
                                    Welcome to the Coin Clash Token Portal
                                </div>
                                <div className="CC-cardBox__body_normal">
                                    <div className="CC-text">
                                        Here you are able to manage, stake and buy your Coin Clash Coins.
                                    </div>
                                    <div className="CC-text">
                                        If you have an account to play games on Coin Clash Games, it will not work here. Please create a new account to buy and manage your coins.
                                    </div>
                                </div>
                            </div>
                            <div className="CC-cardBox  CC-rowelem-right">
                                <div className="CC-cardBox__header">
                                    <div className="CC-cardBox__headerTitle">Log in</div>
                                </div>
                                <Link to="/register">
                                    <div className="CC-cardBox__headerInfo" style={{ cursor: "pointer" }}>
                                        Don't have an account yet? <span className="CC-txtHighlight-secondary">Create an
                                            account</span>
                                    </div>
                                </Link>
                                <div className="CC-cardBox__body">
                                    <div className="CC-form-group">
                                        <div className="CC-form-control">
                                            <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => handleEmail(e)} />
                                        </div>
                                        {emailError && (
                                            <p className="CC-form-errorMsg">{errorMsg}</p>
                                        )}
                                    </div>
                                    <div className="CC-cardBox__headerInfo" />
                                    <div className="CC-form-group">
                                        <div className="CC-form-control">
                                            <input className="input" min={8} type="password" value={password} placeholder="Password" onChange={(e) => handlePassword(e)} />
                                        </div>
                                    </div>
                                    {passwordError && (
                                        <p className="CC-form-errorMsg">{errorMsg}</p>
                                    )}
                                    
                                    <div className="CC-cardBox__headerInfo" />
                                    {/* <div className="CC-form-group">
                                        <div className="CC-checkbox" >
                                            <input id="termsandconditions" onClick={() => handleCheckBox()} value={checkboxSave} name="" type="checkbox" className="checkbox" />
                                            <label htmlFor="termsandconditions"></label>
                                            <span className="CC-checkboxLabel">Keep me signed in</span>
                                        </div>
                                        <div className="CC-form-errorMsg"></div>
                                    </div> */}
                                    {error ? (
                                        <p className="CC-form-errorMsg">{errorMsg}</p>
                                    ): null}
                                    {errorMessage ? (
                                        <p className="CC-form-errorMsg">{errorMessage}</p>
                                    ): null}
                                    <div className="CC-form-label CC-txtHighlight-secondary" onClick={() => props.history.push('/forgotpassword')}>
                                        <a href="/forgotpassword">Forgot password?</a>
                                    </div> 
                                </div>
                                <div className="CC-cardBox__headerInfo"/>
                                <div className="CC-cardBox__footer">
                                    <WaitButton btnclick={onSubmit} processing={processing} text="Log In" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        userData: state.login.userData,
        message: state.login.message
    }
}

export default connect(mapStateToProps)(Login);