import { suscriptionApi } from "../EndPointsConfig/EndPointConfig";
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../Utils/apiCaller";

const SubscriptionSlice = createSlice({
    name: "subscriptionSlice",
    initialState: {
        suscribed : false,
        error: "",
        successMsg : "",
    },
    reducers: {
        setSubscription: (state, action) => {   
            state.suscribed = action.payload;
        },
        setError: (state, action) => {  
            state.error = action.payload;
        },
        setSuccessMsg: (state,action) =>{
            state.successMsg = action.payload;
        }
    }
});

export const { setSubscription, setError, setSuccessMsg } = SubscriptionSlice.actions;

export const getSubscription = (params) => (dispatch) => {

    let obj = {
        email: params.email,
    };

    let url = `${suscriptionApi.suscriptionEndPoint}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            if(response !== null && response) {
                if(response.status != -1){
                    dispatch(setSubscription(true));
                    dispatch(setSuccessMsg("Thank you for subscribing to our Newsletter!"));
                    dispatch(setError(""))
                }
                if(response.status == -1){
                    dispatch(setError(response.message));
                    dispatch(setSuccessMsg(""));
                }
            }
        })
        .catch((error) => dispatch(setError(error.message)));
}

export default SubscriptionSlice.reducer;