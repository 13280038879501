

var devEndPoint=process.env.REACT_APP_DEV_ENDPOINT;
console.log(devEndPoint)

export const userApi = {
  userEndPoint: devEndPoint + "user",
  buyCoinSuccess: devEndPoint + "user/userBitClashAmount",
  metamaskVerify: devEndPoint + "user/metaMaskVerify",
  reserveCoins: devEndPoint + "user/userBitClashAmount",
  transactionStart: devEndPoint + "user/transactionStart",
  transactionFinish: devEndPoint + "user/transactionFinish",
};

export const authApi = {
  authEndPoint: devEndPoint + "auth",
  verifyEmailEndPoint: devEndPoint + "auth/verifyOtp",
  resendOtpEndPoint: devEndPoint + "auth/resendEmailVerificationCode",
}

export const suscriptionApi = {
  suscriptionEndPoint: devEndPoint + "subscribe",
}
  
export const mobileNumApi ={
  mobilenumEndPoint : devEndPoint + "user/addUserMobileNumber",
}

export const changePassword = {
  changePasswordEndPoint : devEndPoint + "auth/forgetPassword"
}
 
export const resetPassword = {
  resetPasswordEndPoint : devEndPoint + "auth/resetPassword"
}
 
export const amountOfBitClash = {

  amountOfBitClashReserveEndPoint : devEndPoint + "user/reservableAmount",
  amountBuy : devEndPoint + "user/amountbuy",
}
export const verifyAccountUserData = {

  verifyUserData: devEndPoint + "user/addUserData",
  getAccountUserData: devEndPoint + "user/userData",
}

export const accountVerificationStatus = {

  accountStatus: devEndPoint + "user/verified",
  usercoins: devEndPoint + "user/usercoins",
  stakeearn: devEndPoint + "user/stakeearn",
  statusSet: devEndPoint + "user/stageset",
}

export const affiliateApi = {
  getLink: devEndPoint + "user/refferlink",
  getData: devEndPoint + "user/refferdata",
}