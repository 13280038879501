
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";
import { amountOfBitClash} from "../../EndPointsConfig/EndPointConfig";
import { userApi } from "../../EndPointsConfig/EndPointConfig";

export const ReserveBitclashSlice = createSlice({
    name: "ReserveBitclashSlice",
    initialState: {
        message:"",
        successMessage: "",
        minAmount:50000,
        maxAmount:50000,
    },
    reducers:{
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setSuccesMessage: (state, action) =>{
            state.successMessage = action.payload;
        },
        setMinAmount: (state, action) => {
            state.minAmount = action.payload;
        },
        setMaxAmount: (state, action) => {
            state.maxAmount = action.payload;
        }
    }
})

export const {setMessage, setSuccesMessage, setMinAmount, setMaxAmount} = ReserveBitclashSlice.actions;

export const getAmountOfBitclashAccess =  () => (dispatch) => {
    let url = `${amountOfBitClash.amountOfBitClashReserveEndPoint}`;
    console.log("amountreservable",url);
    apiCaller(url,"GET")
        .then((response) => {
            console.log(response);
                 if(response.errorCode){
                    dispatch(setMessage(response.message))
                    dispatch(setSuccesMessage(''))
                    dispatch(setMinAmount(50000))
                    dispatch(setMaxAmount(50000));

                }else {
                    dispatch(setMessage(''))
                    dispatch(setSuccesMessage(response.message))
                    dispatch(setMinAmount(response.min));
                    dispatch(setMaxAmount(response.max));
                }
            
        })
        .catch((response) => dispatch(setMessage(response.message)));
}


export const reserveBitClash =  (param, props, processing) => (dispatch) => {
    let obj = {
        amount:parseInt(param),
    };
    console.log("reserveBitClash",obj);
    let url = `${userApi.reserveCoins}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            processing(false);
            if(response !== null && response) {
                if(response.status !== -1){
                    dispatch(setSuccesMessage(response.message));
                    dispatch(setMessage(""));
                    props.history.push("/verifyeth")
                }else{
                    dispatch(setMessage(response.message));
                    dispatch(setSuccesMessage(""));
                }
            }
        })
        .catch((error) => {
            processing(false);
            dispatch(setMessage(error.error))
        });
}

export default ReserveBitclashSlice.reducer;
