

import { createSlice } from "@reduxjs/toolkit";
import { apiCallerNew } from "./apiCallerNew";
import { resetPassword } from "../EndPointsConfig/EndPointConfig";

export const ResetPasswordSlice = createSlice({
  name: "resetPasswordSlice",
  initialState: {
    successMessage: "",
    message: "",
  },
  reducers: {
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },

  },
});
export const { setSuccessMessage, setMessage } = ResetPasswordSlice.actions;

export const getResetPassword = (params, props) => (dispatch) => {
  let obj = {
    password: params.password,
  };
 const tokenId = params.tokenId;

  let url = resetPassword.resetPasswordEndPoint;
  apiCallerNew(url, "POST", obj, tokenId)
    .then((response) => {
      if (response !== null && response) {
        if (response.status !== -1) {
          console.log("sliceres", response.message);
          dispatch(setSuccessMessage(response.message))

        } else {
          console.log("sliceres", response.message);
          dispatch(setMessage(response.message));
        }
      }
    })
    .catch((error) => dispatch(setMessage(error.message)));
};



export default ResetPasswordSlice.reducer;
