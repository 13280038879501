import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAffiliate, getAffiliateData } from './AffiliateSlice';
import { formatUS} from '../Helpers/ConversionApi'

const Affiliate = (props) => {

    const refer_link = useSelector(state => state.affiliate.link);
    const nrRegistered = useSelector(state => state.affiliate.nrRegistered);
    const nrVerified = useSelector(state => state.affiliate.nrVerified);
    const nrSales = useSelector(state => state.affiliate.nrSales);
    const provision = useSelector(state => state.affiliate.provision);

    const dispatch = useDispatch();
    // const handleEmail = (e) => {
    //     setEmail(e.target.value)
    // } 

    useEffect(() => {
        dispatch(getAffiliate({}, props))
        dispatch(getAffiliateData({}, props))
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        // dispatch(getAffiliate({ email }, props))
    }

    const copyLink = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(refer_link)
    }

    return (
        <>
            <div className="CC-section CC-affiliate">
                <div className="CC-container">
                    <div className="CC-section__content stretch">
                        <div className="CC-cardBox CC-rowelem-left">
                            <div className="CC-cardBox__header CC-sectionHeading">
                                Earn by reffering us
                            </div>
                            <div className="CC-cardBox__headerInfo">
                               Share the following link on your social media channels or among your friends and earn 8% of all token purchases made through it!
                            </div>
                            <a href={refer_link} target="_blank" rel="noreferrer">
                                <div className="CC-referr-link">
                                    {refer_link}&nbsp;&nbsp;
                                </div>
                            </a>
                            <div className="CC-buttonGroup">
                                <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnFullWidth"
                                    type="submit" onClick={(e) => copyLink(e)}>
                                    Copy link
                                </button>
                            </div>
                            
                        </div>
                        <div className="CC-cardBox CC-rowelem-right">
                            <div className="CC-cardBox__header CC-sectionHeading">
                                Your referrals
                            </div>
                            <div className="CC-referral-table">
                                <div className="CC-referral-table-row">
                                    <div>Nr. registered</div>
                                    <div>{nrRegistered}</div>
                                </div>
                                <div className="CC-referral-table-row">
                                    <div>Nr. verified</div>
                                    <div>{nrVerified}</div>
                                </div>
                                <div className="CC-referral-table-row">
                                    <div>Nr. sales</div>
                                    <div>{nrSales}</div>
                                </div>
                                <div className="CC-referral-table-row">
                                    <div>Your earnings</div>
                                    <div>$ {formatUS(provision)}</div>
                                </div>
                            </div>

                            <div className="CC-buttonGroup">
                                <button className="btn CC-btnPrimary disabled CC-btnExtraLarge CC-btnHalfWidth"
                                    type="submit" onClick={(e) => copyLink(e)}>
                                    Convert to coins
                                </button>
                                <button className="btn CC-btnPrimary disabled CC-btnExtraLarge CC-btnHalfWidth"
                                    type="submit" onClick={(e) => copyLink(e)}>
                                    Cash out
                                </button>
                            </div>
                            
                        </div>
                    </div>
                    <div className="CC-section__content">
                    </div>
                    <p className={props.error != '' ? "CC-form-errorMsg":undefined || props.successMsg != '' ? "CC-form-successMsg":undefined}>
                        { props.error != "" ? props.error: "" }
                        { props.successMsg != "" ? props.successMsg : ""}
                    </p>
                </div>
            </div>
        </>
    )
}


export default Affiliate;

