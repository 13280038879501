import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../../Utils/apiCaller";
import { userApi } from "../../EndPointsConfig/EndPointConfig";

export const VerifyEthSlice = createSlice({
    name: "verifyEthSlice",
    initialState: {
        errMessage: "",
        successMessage: "",
    },
    reducers: {
        setMessage: (state, action) => {
            state.errMessage = action.payload;
        },

        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        }
    }
})

export const { setMessage, setSuccessMessage } = VerifyEthSlice.actions;

export const verifyEth = (params, props, processing) => (dispatch) => {
    let obj = {
        address: params.addressSend,
        nonce: params.nonce,
        signature: params.signature,
    };

    let url = `${userApi.metamaskVerify}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            console.log(response);
            processing(false);
            if (response !== null && response) {
                if (response.status !== -1) {
                    dispatch(setSuccessMessage(response.message));
                    dispatch(setMessage(""));
                    props.history.push("/buycoinclash");
                } else {
                    dispatch(setMessage(response.message));
                    dispatch(setSuccessMessage(""));
                }
            }
        })
        .catch((error) => {
            dispatch(setMessage(error.error))
            processing(false);
        });
}



export default VerifyEthSlice.reducer;



