export const fieldData = [
    {
        id: 1,
        name:"firstName",
        placeholder:"First Name",
        errorMsg: "Firstname is required"
    },
    {
        id: 2,
        name:"lastName",
        placeholder:"Last Name",
        errorMsg: "Lastname is required"
    },
    {
        id: 3,
        name:"address",
        placeholder:"Address",
        errorMsg: "Address is required"
    },
    {
        id: 4,
        name:"zipCode",
        placeholder:"ZIP code",
        errorMsg: " ZipCode is required"
    },
    {
        id: 5,
        name:"country",
        placeholder:"Country of residence",
        errorMsg: "Country is required"
    },
    {
        id: 6,
        name:"province",
        placeholder:"Province",
        errorMsg: " Province is required"
    },
]