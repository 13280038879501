import React from 'react';

const WaitButton = (props) => {
   
    const handleClick = () => {
        props.btnclick();
    }

    return (
        <button 
            className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" 
            onClick={handleClick}>
            {!props.processing
                ? props.text
                : <div className="ccbtnspinnerHolder">
                    Processing ... 
                    <span className="ccbtnspinner"></span>
                </div>
            }        
        </button>
    );
}

export default WaitButton;