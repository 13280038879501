import { ethers } from 'ethers';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Barcode from '../../../assets/images/other-icons/barcode.svg';
import closeIcon from '../../../assets/images/other-icons/close.svg';
import {bitclashToEth} from '../../Helpers/ConversionApi';
import { updateTxnStatus } from './BuyCoinProcessingSlice';
import QRCode from "react-qr-code";

const BuyCoinsProcessing = (props) => {
    const dispatch = useDispatch();

    const handleTransaction = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if(props.txHash === '') return
        const txn = await provider.getTransaction(props.txHash);
        const transaction = await txn.wait();
        const txnStatus =  (transaction.status === 1) ? 1 : -1;
        dispatch(updateTxnStatus({txhash:transaction.transactionHash, status: txnStatus}));
        props.setTxHash('');
        (txnStatus === 1 && props.history.push("/buycoinsuccess"));
    }
    
    useEffect (()=>{
        handleTransaction()},
         [])

    return (
        <>
                <div className="CC-layout-content">
                    <div className="CC-section CC-innerPage">
                        <div className="CC-container">
                            <div className="CC-section__content CC-processing">
                                <div className="CC-cardBox">
                                    <div className="CC-cardBox__header">
                                        <div className="CC-cardBox__headerTitle">Processing</div> 
                                        <div className="CC-cardBoxClose">
                                            <img onClick={() => props.history.push("/dashboard")} alt="" src={closeIcon} />
                                        </div>
                                    </div>
                                    <div className="CC-cardBox__body">
                                        <div className="CC-cardBox__bodyDesc">Your transaction is processing</div>
                                        <div className="CC-space20"></div>
                                        <div className="CC-space20"></div>
                                        <div>
                                            <span className="CC-txtHighlight-secondary"><a href={`https://rinkeby.etherscan.io/tx/${props.txHash}`} target="_blank">click</a></span> or scan the code
                                        </div>
                                        <div className="CC-barcode">
                                        <a href={`https://rinkeby.etherscan.io/tx/${props.txHash}`} rel="noreferrer" target="_blank">
                                            <QRCode size="128" value={`https://rinkeby.etherscan.io/tx/${props.txHash}`} />
                                        </a>
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default BuyCoinsProcessing
