import './assets/css/styles-coinclash.scss'
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer';
import Dashboard from './components/Dashboard/Dashboard';
import Subscription from './components/Subscription/Subscription';
import Affiliate from './components/Affiliate/Affiliate';
import BitClash from './components/BitClash/BitClash';
import AccountVerification from './components/AccountVerification/AccountVerification';
import VerifyEmail from './components/Modal_Boxes/VerifyEmail/VerifyEmail';
import VerifiedEmail from './components/Modal_Boxes/VerifiedEmail/VerifiedEmail';
import VerifyAccount from './components/Modal_Boxes/VerifyAccount/VerifyAccount';
import PreIdVerify from './components/Modal_Boxes/PreIdVerify/PreIdVerify';
import VerifyEth from './components/Modal_Boxes/VerifyEth/VerifyEth';
import IdVerification from './components/Modal_Boxes/IdVerification/IdVerification';
import ReserveBitClash from './components/Modal_Boxes/ReserveBitclash/ReserveBitclash';
import BuyCoinClash from './components/Modal_Boxes/BuyCoinClash/BuyCoinClash';
import BuyCoinsSuccess from './components/Modal_Boxes/BuyCoinSuccess/BuyCoinsSuccess';
import BuyCoinNotification from './components/Modal_Boxes/BuyCoinNotification/BuyCoinNotification';
import BuyCoinsProcessing from './components/Modal_Boxes/BuyCoinProcessing/BuyCoinsProcessing';
import MyEarningsPage from './components/Modal_Boxes/MyEarningPage/MyEarningsPage';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import ChangePassword from './components/Modal_Boxes/ChangePassword/ChangePassword';
import { minVal, maxVal } from '../src/components/Constant/constant'
import { conversionApi } from '../src/components/Helpers/ConversionApi';
import FinishedMobileNumber from './components/Modal_Boxes/FinishedMobileNumber/FinishedMobileNumber';
import VerificationFailedFinish from './components/Modal_Boxes/VerificationFailedFinish/VerificationFailedFinish';
import ResetPassword from './components/ResetPassword/ResetPassword';

function App() {
  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = parseJwt(token);

  const [authorization, setAuthorization] = useState(decodedJwt && decodedJwt.exp * 1000 > Date.now());
  const [loggedIn, setLoggedIn] = useState(decodedJwt && decodedJwt.exp * 1000 > Date.now());
  const [keepSingedIn, setKeepSignedIn] = useState(localStorage.getItem("email") !== null);
  const [email, setEmail] = useState("");
  const [amountGlobal, setAmountGlobal] = useState(0)
  const [amountEthGlobal, setAmountEthGlobal] = useState(0);
  const [accountVerified, setAccountVerified] = useState(false)
  const [amountEth, setAmountEth] = useState(0);
  const [buySucess, setBuySuccess] = useState(false);
  const [txHash, setTxHash] = useState("");


  const MainDashboard = (props) => {
    return (
      <div className='CC-layout-content'>
        {/* {
          buySucess ? <MyEarningsPage  amountEthGlobal={amountEthGlobal}   {...props} /> : <AccountVerification   {...props} verified={accountVerified}/>
        } */}
        {/* <Dashboard   {...props} amount={amountGlobal !== 0 ? amountGlobal : minVal} /> */}
        <AccountVerification   {...props} verified={accountVerified} amountEthGlobal={amountEthGlobal} />
        <BitClash {...props} />
        <Affiliate {...props} />
        <Subscription   {...props} />
      </div>
    )
  }

  const handleKeepMeSignedIn = (email) => {
    // if (email) {
    //   setKeepSignedIn(true)
    //   localStorage.setItem("email", email)
    // }
  }

  const handleEthAmountChange = (amount) => {
    setAmountEthGlobal(amount)
  }

  const handleBuySucess = () => {
    setBuySuccess(true)
  }

  const handleTxHash = (txHash) => {
    setTxHash(txHash)
  }

  const handleLoggedIn = () => {
    setLoggedIn(true)
  }

  const handleAmountChange = (amount) => {
    setAmountGlobal(amount)
  }

  const handleLogoutSuccess = () => {
    setEmail("")
    setKeepSignedIn(false)
    setLoggedIn(false)
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload(true);
    }
  }

  const handleAuthorization = () => {
    setAuthorization(true);

  }

  const handleEmailChange = (email) => {
    setEmail(email)
  }

  useEffect(() => {
    conversionApi(setAmountEth)
  }, [])
  return (
    <BrowserRouter>
      <div className="App">
        <header className='CC-mainHeader__sticky'>
          <Header authorization={loggedIn} handleLogoutSuccess={handleLogoutSuccess} />
        </header>
        <Switch>
          <Route path="/login" render={(props) => <Login handleKeepMeSignedIn={handleKeepMeSignedIn} handleLoggedIn={handleLoggedIn} handleAuthorization={handleAuthorization} handleEmailChange={handleEmailChange} {...props} />} />
          <Route path="/register" render={(props) => <Register myProp="value" handleEmailChange={handleEmailChange} email={email} {...props} />} />
          <Route path="/forgotpassword" render={(props) => <ChangePassword email={email} handleEmailChange={handleEmailChange} {...props} />} />
          <Route path="/resetpassword" render={(props) => <ResetPassword email={email} handleEmailChange={handleEmailChange} {...props} />} />
          <Route path="/verifyemail" render={(props) => email ? (<VerifyEmail email={email}  {...props} />) : (<Redirect to="/register" />)} />
          <Route path="/verifiedemail" render={(props) => email ? (<VerifiedEmail handleLoggedIn={handleLoggedIn} {...props} />) : (<Redirect to="/register" />)} />
          <Route path="/dashboard" render={(props) =>authorization ? (<MainDashboard myProp="value" {...props} />) : ( <Redirect to="/login" />) }/>
          <Route path="/verifyaccount" render={(props) => authorization ? (<VerifyAccount myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/verifyeth" render={(props) => authorization ? (<VerifyEth myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/reservebitclash" render={(props) => authorization ? (<ReserveBitClash myProp="value" amountEth={amountEth} handleAmountChange={handleAmountChange} {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/buycoinclash" render={(props) => authorization ? (<BuyCoinClash myProp="value" amountEth={amountEth} setTxHash={setTxHash} handleAmountChange={handleAmountChange} {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/accountverified" render={(props) => authorization ? (<AccountVerification myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/buycoinprocessing" render={(props) => authorization ? (<BuyCoinsProcessing myProp="value" amountEth={amountEth} txHash={txHash} setTxHash={setTxHash} amount={amountGlobal} {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/buycoinsuccess" render={(props) => authorization ? (<BuyCoinsSuccess handleEthAmountChange={handleEthAmountChange} handleBuySucess={handleBuySucess} amount={amountGlobal} amountEth={amountEth} {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/buycoinnotification" render={(props) => authorization ? (<BuyCoinNotification handleEthAmountChange={handleEthAmountChange} handleBuySucess={handleBuySucess} amount={amountGlobal} amountEth={amountEth} {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/preidverify" render={(props) => authorization ? (<PreIdVerify myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/idverification" render={(props) => authorization ? (<IdVerification {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/bitclash" render={(props) => authorization ? (<BitClash myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/finishedmobilenumber" render={(props) => authorization ? (<FinishedMobileNumber myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="/verificationfinish" render={(props) => authorization ? (<VerificationFailedFinish myProp="value" {...props} />) : (<Redirect to="/login" />)} />
          <Route path="" render={(props) => <Login handleKeepMeSignedIn={handleKeepMeSignedIn} handleLoggedIn={handleLoggedIn} handleAuthorization={handleAuthorization} handleEmailChange={handleEmailChange} {...props} />} />
        </Switch>
        <footer className="CC-footer">
          <Footer />
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;