import { handleResponse, handleError } from "./apiUtil";
import { userApi, authApi } from "../components/EndPointsConfig/EndPointConfig";
export function apiCaller(url, method, params) {
  var options = {};

  options.method = method;

  var headers = {
    ...(url == `${authApi.verifyEmailEndPoint}`
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: 'Bearer '+ localStorage.getItem("token"),
        }
      : {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: 'Bearer '+ localStorage.getItem("token"),
        }),
  };
  var query = "";
  if (params && Object.keys(params).length > 0) {
    if (method == "POST") {
      options.headers = headers;
      options.body = JSON.stringify(params);
      // console.log("body", JSON.stringify(params))
    } else if (method == "PUT") {
      options.headers = headers;
      options.body = JSON.stringify(params);
    } else {
      query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");
    }
  }

  return new Promise((resolve, reject) => {
    if (method === "POST") {
      options.headers = headers;
      options.body = JSON.stringify(params);
      //console.log("opt",options)
      fetch(url, options)
        .then((response) => {
          if (response.status === 200) {
            response.json().then((json) => {
              return resolve(json);
            });
          }
          if (response.status === 400) {
            response.json().then((json) => {
              reject(json);
            });
          }
        })
        .catch(handleError);
    } else if (method == "PUT") {
      options.headers = headers;
      fetch(url, options)
        .then((response) => {
          if (response.status === 200) {
            response.json().then((json) => {
              return resolve(json);
            });
          }
          if (response.status === 400) {
            response.json().then((json) => {
              reject(json);
            });
          }
        })
        .catch(handleError);
    } else {
      options.headers = headers;
      fetch(url + query, options)
        .then((response) => {
          if (response.status === 200) {
            response.json().then((json) => {
              return resolve(json);
            });
          }
          if (response.status === 400) {
            response.json().then((json) => {
              resolve(json);
            });
          }
        })
        .catch(handleError);
    }
  });
}
