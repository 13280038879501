
import { configureStore } from "@reduxjs/toolkit"
import LoginSlice from "../../components/Login/LoginSlice";
import RegisterSlice from "../../components/Register/RegisterSlice";
import VerifyEmailSlice from "../Modal_Boxes/VerifyEmail/VerifyEmailSlice";
import BuyCoinSuccessSlice from "../Modal_Boxes/BuyCoinSuccess/BuyCoinSuccessSlice";
import ReserveBitclashSlice from "../Modal_Boxes/ReserveBitclash/ReserveBitclashSlice";
import BuycoinclashSlice from "../Modal_Boxes/BuyCoinClash/BuyCoinClashSlice";
import VerifyAccountSlice from "../Modal_Boxes/VerifyAccount/VerifyAccountSlice";
import SubscriptionSlice from "../../components/Subscription/SubscriptionSlice";
import FinishedMobileNumberSlice from "../Modal_Boxes/FinishedMobileNumber/FinishedMobileNumberSlice";
import  VerifyEthSlice  from "../Modal_Boxes/VerifyEth/VerifyEthSlice";
import ChangePasswordSlice from "../Modal_Boxes/ChangePassword/ChangePasswordSlice";
import ResetPasswordSlice from "../ResetPassword/ResetPasswordSlice";
import AccountVerificationSlice from "../AccountVerification/AccountVerificationSlice";
import AffiliateSlice from "../Affiliate/AffiliateSlice";

export default configureStore({
    reducer: {
        login: LoginSlice,
        register: RegisterSlice,
        verifyemail : VerifyEmailSlice,
        reservecoins : ReserveBitclashSlice,
        buycoins : BuycoinclashSlice,
        buycoinsuccess : BuyCoinSuccessSlice,
        verifyaccount : VerifyAccountSlice,
        verifyETH : VerifyEthSlice,
        subscription : SubscriptionSlice,
        finishedMobileNumber: FinishedMobileNumberSlice,
        changePassword : ChangePasswordSlice,
        resetPassword: ResetPasswordSlice,
        accountVerification: AccountVerificationSlice,
        affiliate: AffiliateSlice,
    }
});