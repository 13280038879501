import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";

export const IdVerificationSlice = createSlice({
    name: "IdVerificationSlice",
    initialState: {
        message:"",
        successMessage: "",
    },
    reducers:{
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setSuccesMessage: (state, action) =>{
            state.successMessage = action.payload;
        }
    }
})

export const GetVerificationToken =  () => (dispatch) => {
    let url = 'https://ivs.idenfy.com/api/v2/token';
    console.log("GetVerificationToken",url);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
}
