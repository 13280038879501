import { authApi } from "../EndPointsConfig/EndPointConfig";
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../Utils/apiCaller";

export const LoginSlice = createSlice({
    name: "loginSlice",
    initialState: {
      userData: [],
      password: "",
      userName: "",
      token: false,
      errorMessage: "",
      successMessag: "",
      message: "",
    },
    reducers: {
      setLogin: (state, action) => {
        state.userData = action.payload;
      },
      setMessage: (state, action) => {
        state.message = action.payload;
      },
      setErrorMessage: (state, action) => {
        state.errorMessage = action.payload;
      },
      setToken: (state, action) => {
        state.token = action.payload;
      },
    },
  });
  export const { setLogin, setErrorMessage, setToken } = LoginSlice.actions;

export const getLoginAccess = (params, props, processing) => (dispatch) => {
  let obj = {
    password: params.password,
    email: params.email,
  };

  let url = `${authApi.authEndPoint}`+"/signin";
  apiCaller(url, "POST", obj)
    .then((response) => {
      processing(false);
      if(response !== null && response) {
        if(response.status !== -1){
          localStorage.setItem("token", response.token);
          props.handleLoggedIn()
          props.handleAuthorization()
          props.history.push("/dashboard");

        }else{
          dispatch(setErrorMessage(response.message));
        }
      }
    })
    .catch((error) => {
        dispatch(setErrorMessage(error.message))
        processing(false);
      }
    );
};

export default LoginSlice.reducer;
