import { affiliateApi } from "../EndPointsConfig/EndPointConfig";
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../Utils/apiCaller";

const AffiliateSlice = createSlice({
    name: "affiliateSlice",
    initialState: {
        link: "",
        error: "",
        successMsg : "",
        nrRegistered : 0,
        nrVerified : 0,
        nrSales : 0,
        provision : 0,
    },
    reducers: {
        setAffiliateLink: (state, action) => {   
            state.link = action.payload;
        },
        setError: (state, action) => {  
            state.error = action.payload;
        },
        setNrRegistered: (state,action) =>{
            state.nrRegistered = action.payload;
        },
        setNrVerified: (state, action) => {   
            state.nrVerified = action.payload;
        },
        setNrSales: (state, action) => {   
            state.nrSales = action.payload;
        },
        setProvision: (state, action) => {   
            state.provision = action.payload;
        },
    }
});

export const { setAffiliateLink, setError, setSuccessMsg, setNrRegistered, setNrVerified, setNrSales, setProvision  } = AffiliateSlice.actions;

export const getAffiliate = () => (dispatch) => {

    let url = `${affiliateApi.getLink}`;
    apiCaller(url, "GET", {})
        .then((response) => {
            if (response !== null && response) {
              if (response.status === 1) {
                dispatch(setAffiliateLink(response.link))
              } else {
                dispatch(setError(response.message));
              }
            }
        })
        .catch((error) => dispatch(setError(error.message)));
}

export const getAffiliateData = () => (dispatch) => {

    let url = `${affiliateApi.getData}`;
    apiCaller(url, "GET", {})
        .then((response) => {
            if (response !== null && response) {
              if (response.status === 1) {
                dispatch(setNrRegistered(response.registered))
                dispatch(setNrVerified(response.verified))
                dispatch(setNrSales(response.sales))
                dispatch(setProvision(response.provision))
              } else {
                dispatch(setError(response.message));
              }
            }
        })
        .catch((error) => dispatch(setError(error.message)));
}

export default AffiliateSlice.reducer;