import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {getBuyLimits, updateTxnHash} from './BuyCoinClashSlice';
import { minVal, maxVal } from '../../Constant/constant'
import {bitclashToUstc, bitclashToEth, formatNumber, bitclashToBTC, bitclashToBNB, bitclashRate} from '../../Helpers/ConversionApi'
import {ethers} from 'ethers';
import { conversionApi, conversionApiBTC, conversionApiBNB } from '../../Helpers/ConversionApi';
import QRCode from "react-qr-code";


const BuyCoinClash = (props) => {
    const maxAmount = useSelector((state) => state.buycoins.maxAmount)
    const buychain = useSelector((state) => state.buycoins.chain)

    const [amount, setAmount] = React.useState(maxAmount)
    const [amountError, setAmountError] =React.useState(false)
    const [metamaskerror, setMetamaskerror] = React.useState('')
    const [ethToUSD, setEthToUSD] = React.useState(0)
    const [BTCtoUSD, setBTCtoUSD] = React.useState(0)
    const [BNBtoUSD, setBNBtoUSD] = React.useState(0)
    const Amount = amount ; 
    const dispatch = useDispatch();
    const QRcodeSize = 128;

    const ETHAddfress = "0xD19b5406Ac1b779EAe3d1ecb1E35c0821672CB46";
    const BTCAddress = "bc1p36h69vwpwkfq58qunt47aanx6kc7demz3wj59s0s2nmx96sqrz9syhsl4h";
    const BNBAddress = "0xD19b5406Ac1b779EAe3d1ecb1E35c0821672CB46";
    const MATICAddress = "0x901a96Ad8C07077Bc48B0aD9400531d4fB62b48F";

    const setMetamaskError = (error) =>{
        setMetamaskerror(error);
        setTimeout(() => {
            setMetamaskerror('');
        } , 5000);
    }

    useEffect(() => {
        dispatch(getBuyLimits())
    }, [])


    const handleClose = async () =>{
        props.history.push("/buycoinnotification");
    }
    
    const handleBuy = async () =>{
        // props.handleAmountChange(amount)
        if(!(window.ethereum && window.ethereum.isMetaMask)){
            setMetamaskError('Please install MetaMask')
            return;
        }
            try {
              const accounts = await window.ethereum.request({ method: "eth_accounts" });
              (accounts.length === 0 && await window.ethereum.request({ method: "eth_requestAccounts" }));
            //   switch network to Rinkeby
              try{
                  if(window.ethereum.chainId !== "0x1") setMetamaskerror("switching to mainnet network...") //change in final build
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: '0x1' }],  // 0x4 is the id of the Rinkeby chain //change it in the final build
                });
                }
              catch(err){
                console.log(err); 
                return;
                }
              setMetamaskerror('')
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const signer = provider.getSigner();
              const address = await signer.getAddress();                
              await conversionApi(setEthToUSD);
              
              const ethAmount = (bitclashRate * maxAmount/ethToUSD).toFixed(17); //change it in the final build
              console.log("ethAmount: " + ethAmount)
              const transaction =  (await signer.sendTransaction({to:ETHAddfress, 
              value: ethers.utils.parseEther(ethAmount.toString())})); //change it in the final build
              dispatch(updateTxnHash({txnHash:transaction.hash, address:address, bitclashAmount:maxAmount}));
              props.setTxHash(transaction.hash);
              props.history.push("/buycoinprocessing");
            } catch (e) {
              if (e.code === 4001) {
                  setMetamaskError("User declined the metamask request")
              }
              else {
                  const error = e.toString();
                  if(error.includes("insufficient funds")){
                      setMetamaskError("Insufficient Funds")
                  }
                  else if (error.includes("user is not whitelisted")){
                      setMetamaskError("User is not whitelisted")
                  }
                  else if (error.includes("amount should be more than zero and less than the reserved amount")){
                    if (amount<=0) setMetamaskError("Coins amount should be more than zero")    
                    else setMetamaskError("Cannot purhase more than the reserved amount")
                  }
                  else{
                        setMetamaskError("Something went wrong");
                        console.log(e);
                  } 
              }
            }
    };

    const changeReserve = (() => {
        console.log('change reserve')
        props.history.push('/reservebitclash')
    })

    useEffect (() => {
        // if (amount !== 0) {
        //     props.history.push('/verifyeth')
        // } else {
            conversionApi(setEthToUSD)
            conversionApiBTC(setBTCtoUSD)
            conversionApiBNB(setBNBtoUSD)
        // }
    }, [])

    const buyOnETH = 
    <>

        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-amountOfBitClash">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__body">
                                <div>
                                    You are purchasing:<br />
                                </div>
                                <h2 className="CC-txtHighlight-secondary numbercoins">
                                    {formatNumber(maxAmount)} Coin Clash Coins
                                </h2>
                                <div>
                                    Price: <span className="CC-txtHighlight-secondary">{bitclashToEth(maxAmount/props.amountEth)} ETH</span> or <span
                                        className="CC-txtHighlight-secondary">{bitclashToUstc(maxAmount)} USDC</span>
                                </div>
                                <div className="margin-t-20">
                                    Please make the transfer to the following address:
                                </div>
                                <div className="numbercoins">
                                    <QRCode size={QRcodeSize} value={ETHAddfress} />
                                </div>

                                <div className="walletaddress">
                                    {ETHAddfress} 
                                    <i onClick={() =>  navigator.clipboard.writeText(ETHAddfress)} className="material-icons copy-icon margin-left-20">content_copy</i>
                                </div>

                                <div className="margin-t-20">
                                <span className="CC-txtHighlight-secondary">IMPORTANT!</span><br/> You should use only the address that you whitelisted so that we can trace the transaction. If we are not able to verify the buyer we will return the amount received to the address you paid from less the transaction and handling fees.
                                </div>
                            </div>
                            <div className='CC-cardBox__body'>
                                {metamaskerror !== '' && <p className="CC-form-errorMsg">{metamaskerror}</p>}
                            </div>
                            {window.ethereum && window.ethereum.isMetaMask ? 
                                <>
                                    <div className="CC-form-group">
                                        <button className="btn btnRect CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleBuy()}>Buy with Metamask</button>
                                    </div>
                                </>:
                                <></>
                            }
                            <div className="CC-cardBox__footer twobuttons">
                                <button className="btn btnRect CC-btnSecondary active CC-btnExtraLarge CC-btnCardBox" onClick={() => changeReserve()}>Other method</button>
                                <button className="btn btnRect CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleClose()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    const buyOnBTC = <>
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-amountOfBitClash">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__body">
                                <div>
                                    You are purchasing:<br />
                                </div>
                                <h2 className="CC-txtHighlight-secondary numbercoins">
                                    {formatNumber(maxAmount)} Coin Clash Coins
                                </h2>
                                <div>
                                    Price: <span className="CC-txtHighlight-secondary">{bitclashToBTC(maxAmount, BTCtoUSD)} BTC</span>
                                </div>
                                <div className="margin-t-20">
                                    Please make the transfer to the following address:
                                </div>
                                <div className="numbercoins">
                                    <QRCode size={QRcodeSize} value={BTCAddress} />
                                </div>

                                <div className="walletaddress">
                                    {BTCAddress} 
                                    <i onClick={() =>  navigator.clipboard.writeText(BTCAddress)} className="material-icons copy-icon margin-left-20">content_copy</i>
                                </div>

                                <div className="margin-t-20">
                                <span className="CC-txtHighlight-secondary">IMPORTANT!</span><br/> You should use only the address that you whitelisted so that we can trace the transaction. If we are not able to verify the buyer we will return the amount received to the address you paid from less the transaction and handling fees.
                                </div>
                            </div>
                            <div className='CC-cardBox__body'>
                                {metamaskerror !== '' && <p className="CC-form-errorMsg">{metamaskerror}</p>}
                            </div>
                            <div className="CC-cardBox__footer twobuttons">
                                <button className="btn btnRect CC-btnSecondary active CC-btnExtraLarge CC-btnCardBox" onClick={() => changeReserve()}>Other method</button>
                                <button className="btn btnRect CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleClose()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    const buyOnBNB = <>
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-amountOfBitClash">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__body">
                                <div>
                                    You are purchasing:<br />
                                </div>
                                <h2 className="CC-txtHighlight-secondary numbercoins">
                                    {formatNumber(maxAmount)} Coin Clash Coins
                                </h2>
                                <div>
                                    Price: <span className="CC-txtHighlight-secondary">{bitclashToBNB(maxAmount, BNBtoUSD)} BNB</span> or <span
                                        className="CC-txtHighlight-secondary">{bitclashToUstc(maxAmount)} USDC</span>
                                </div>
                                <div className="margin-t-20">
                                    Please make the transfer to the following address:
                                </div>
                                <div className="numbercoins">
                                    <QRCode size={QRcodeSize} value={BNBAddress} />
                                </div>

                                <div className="walletaddress">
                                    {BNBAddress} 
                                    <i onClick={() =>  navigator.clipboard.writeText(BNBAddress)} className="material-icons copy-icon margin-left-20">content_copy</i>
                                </div>

                                <div className="margin-t-20">
                                <span className="CC-txtHighlight-secondary">IMPORTANT!</span><br/> You should use only the address that you whitelisted so that we can trace the transaction. If we are not able to verify the buyer we will return the amount received to the address you paid from less the transaction and handling fees.
                                </div>
                            </div>
                            <div className='CC-cardBox__body'>
                                {metamaskerror !== '' && <p className="CC-form-errorMsg">{metamaskerror}</p>}
                            </div>
                            <div className="CC-cardBox__footer twobuttons">
                                <button className="btn btnRect CC-btnSecondary active CC-btnExtraLarge CC-btnCardBox" onClick={() => changeReserve()}>Other method</button>
                                <button className="btn btnRect CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleClose()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    const buyOnMATIC = <>
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-amountOfBitClash">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__body">
                                <div>
                                    You are purchasing:<br />
                                </div>
                                <h2 className="CC-txtHighlight-secondary numbercoins">
                                    {formatNumber(maxAmount)} Coin Clash Coins
                                </h2>
                                <div>
                                    Price: <span className="CC-txtHighlight-secondary">{bitclashToUstc(maxAmount)} USDC</span>
                                </div>
                                <div className="margin-t-20">
                                    Please make the transfer to the following address:
                                </div>
                                <div className="numbercoins">
                                    <QRCode size={QRcodeSize} value={MATICAddress} />
                                </div>

                                <div className="walletaddress">
                                    {MATICAddress} 
                                    <i onClick={() =>  navigator.clipboard.writeText(MATICAddress)} className="material-icons copy-icon margin-left-20">content_copy</i>
                                </div>

                                <div className="margin-t-20">
                                <span className="CC-txtHighlight-secondary">IMPORTANT!</span><br/> You should use only the address that you whitelisted so that we can trace the transaction. If we are not able to verify the buyer we will return the amount received to the address you paid from less the transaction and handling fees.
                                </div>
                            </div>
                            <div className='CC-cardBox__body'>
                                {metamaskerror !== '' && <p className="CC-form-errorMsg">{metamaskerror}</p>}
                            </div>
                            <div className="CC-cardBox__footer twobuttons">
                                <button className="btn btnRect CC-btnSecondary active CC-btnExtraLarge CC-btnCardBox" onClick={() => changeReserve()}>Other method</button>
                                <button className="btn btnRect CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleClose()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    const buyOnExchange = <>
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-amountOfBitClash">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__body">
                                <div>
                                    You are purchasing:<br />
                                </div>
                                <h2 className="CC-txtHighlight-secondary numbercoins">
                                    {formatNumber(maxAmount)} Coin Clash Coins
                                </h2>
                                <div>
                                    Price: <span className="CC-txtHighlight-secondary">{bitclashToEth(maxAmount/props.amountEth)} ETH</span> or <span
                                        className="CC-txtHighlight-secondary">{bitclashToUstc(maxAmount)} USDC</span>
                                </div>
                                <div className="margin-t-20">
                                    Please make the transfer to the following address:
                                </div>
                                <div className="numbercoins">
                                    <QRCode size={QRcodeSize} value={ETHAddfress} />
                                </div>

                                <div className="walletaddress">
                                    {ETHAddfress} 
                                    <i onClick={() =>  navigator.clipboard.writeText(ETHAddfress)} className="material-icons copy-icon margin-left-20">content_copy</i>
                                </div>

                                <div className="margin-t-20">
                                <span className="CC-txtHighlight-secondary">IMPORTANT!</span><br/> When paying from exchange wallet address, please send funds to wallet address above and use ETHEREUM or BNB network. Before making a transfer you need to contact us on <a href="mailto:tokensale@coin-clash.com">tokensale@coin-clash.com</a>, because otherwise we will not be able to trace the transfer to you and credit your account.
                                </div>
                            </div>
                            <div className='CC-cardBox__body'>
                                {metamaskerror !== '' && <p className="CC-form-errorMsg">{metamaskerror}</p>}
                            </div>
                            <div className="CC-cardBox__footer">
                                <button className="btn btnRect CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox" onClick={() => handleClose()}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    return (
        <>
            {buychain === "ETH" && buyOnETH}
            {buychain === "BTC" && buyOnBTC}
            {buychain === "BNB" && buyOnBNB}
            {buychain === "MAT" && buyOnMATIC}
            {buychain === "EXC" && buyOnExchange}
        </>
    )
}

export default BuyCoinClash