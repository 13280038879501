import React from 'react'
import { conversionApi, conversionApiBTC, conversionApiBNB, formatNumber, bitclashToEth, ethToBitClash } from '../Helpers/ConversionApi';

import exchange from '../../assets/images/exchange.svg'


const BitClash = (props) => {
    const [amount, setAmount] = React.useState(1)
    const [apiAmount, setApiAmount] = React.useState(0)
    const [BTCrate, setBTCrate] = React.useState(0)
    const [BNBRate, setBNBRate] = React.useState(0)
    const [bitClash, setBitClash] = React.useState(0)

    React.useEffect(() =>{
        conversionApi(setApiAmount)
        conversionApiBTC(setBTCrate)
        conversionApiBNB(setBNBRate)
    },[])

    React.useEffect(()=>{ 
        setBitClash(ethToBitClash(amount, apiAmount))
    },[apiAmount])

    // const onChangeAmount = (e) =>{
    //     if(e.target.value === ""){
    //         setAmount(1)
    //         setBitClash(ethToBitClash(1, apiAmount))
    //     }else{
    //         setAmount(e.target.value)
    //         setBitClash(ethToBitClash(e.target.value, apiAmount))
    //     }
    // }

    // const handleBitClashChnage = (e) =>{
    //     if(e.target.value !== ""){
    //         setBitClash(e.target.value)
    //         setAmount(bitclashToEth(e.target.value/apiAmount))
    //     }else{
    //         setAmount(1)
    //         setBitClash(ethToBitClash(1, apiAmount))
    //     }
    // }

    return (
        <>
            <div className="CC-section CC-exchange">
                <div className="CC-container">
                    <div className="CC-exchangeS">
                        <div className="CC-exchange-content">
                            1 USD
                            <div className="CC-exchange-btn">
                                <img src={exchange} className="exchangeLogo" alt="Exchange" />
                            </div>
                            10 Coin Clash Coins
                        </div>
                        <div className="CC-exchange-content">
                            1 ETH
                            <div className="CC-exchange-btn">
                                <img src={exchange} className="exchangeLogo" alt="Exchange" />
                            </div>
                            {formatNumber(bitClash)} Coin Clash Coins
                        </div>
                    </div>
                    <div className="CC-exchangeS">
                        <div className="CC-exchange-content">
                            1 BTC
                            <div className="CC-exchange-btn">
                                <img src={exchange} className="exchangeLogo" alt="Exchange" />
                            </div>
                            {formatNumber(10 * BTCrate)} Coin Clash Coins
                        </div>
                        <div className="CC-exchange-content">
                            1 BNB
                            <div className="CC-exchange-btn">
                                <img src={exchange} className="exchangeLogo" alt="Exchange" />
                            </div>
                            {formatNumber(10 * BNBRate)} Coin Clash Coins
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.subscription.error,
        successMsg: state.subscription.successMsg
    }
}

export default BitClash
