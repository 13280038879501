import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import {getMobileNumberAccess, setStatusFailed} from './VerificationFailedFinishSlice'


const FinishedMobileNumber = (props) => {

const [ mobileNumber, setMobileNumber] = React.useState('');
const [err, setErr] = React.useState(false)
const dispatch = useDispatch();

const handleSubmit = () => {
    const num = mobileNumber.length == 6 ? mobileNumber : ''
    dispatch(getMobileNumberAccess({mobileNumber :num}, props));
    if(mobileNumber !== 6){
      setErr(true)
    }else{
        setErr(false)
    props.verifyAccountSuccess()
    }
}


useEffect(()=>{
    dispatch(setStatusFailed())
},[])

useEffect(()=>{
    props.successMessage !== "" && setTimeout(()=> props.history.push('/dashboard'),2000)
},[props.successMessage])

  return (
   
      <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-finished">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__header">
                                <div className="CC-cardBox__headerTitle">Automated verification failed</div>
                            </div>
                            <div className="CC-cardBox__body">
                                <p>
                                    Thank you for taking part in the verification process. Unfortunatelly, the automated verification of your documents has failed. We will now manually proceed with verification process.
                                </p>
                                <div className="CC-space20"></div>
                                <p>
                                    It might take up to 5 working days for us to review the information and we will be in touch with you if we need any more information.
                                </p>
                                <div className="CC-space20"></div>
                                <p>
                                    If you wish for us to notify you with an SMS when completed,
                                    please provide us with your mobile number.
                                </p>
                                <div className="CC-form-group CC-inputBtnGroup">
                                    <div className="CC-form-control">
                                        <input type="number" placeholder="Phone number (Optional)" 
                                        value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)}

                                        />
                                    </div>
                                    <button 
                                    onClick={() => handleSubmit()}
                                        className="btn btcRect CC-btnSecondary active CC-btnExtraLarge CC-btnCardBox">Submit</button>
                                </div>
                            </div>
                           <p className={props.message !== '' ? "CC-form-errorMsg" : props.successMessage !== '' ? "CC-form-successMsg": undefined}>
                                {err && props.message }
                                {err && props.successMessage }
                            </p>
                         
                            <div className="CC-cardBox__footer">
                                <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"
                                 onClick={() => props.history.push("/dashboard")}
                                    >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
  )
}
const mapStateToProps = (state) =>{
    return{
        message: state.finishedMobileNumber.message,
        successMessage: state.finishedMobileNumber.successMessage,
    }
}

export default connect(mapStateToProps)(FinishedMobileNumber)

