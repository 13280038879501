import { userApi } from "../../EndPointsConfig/EndPointConfig";
import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";

export const BuyCoinSuccessSlice = createSlice({
    name: "buyCoinSuccessSlice",
    initialState: {
        amountglobal:0,
        message:""
    },
    reducers:{
        setAmount: (state, action) => {
            state.amountglobal = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        }
    }
})

export const {setAmount, setMessage} = BuyCoinSuccessSlice.actions;

export const buySuccess =  (params, props) => (dispatch) => {
    let obj = {
        amount:props.amount,
    };

    let url = `${userApi.buyCoinSuccess}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            if(response !== null && response) {
                if(response.amount !== ""){
                    dispatch(setAmount(response.amount))
                    props.handleEthAmountChange(response.amount)
                    props.history.push('/dashboard')
                }else{
                    dispatch(setMessage(response.error));
                }
            }
        })
        .catch((error) => dispatch(setMessage(error.error)));
}

export default BuyCoinSuccessSlice.reducer;