import React from 'react'
import WaitButton from "../../../Elements/WaitButton/WaitButton";

import iDenfyLogo from '../../../assets/images/idenfy-logo-purple.svg'

const PreIdVerify = (props) => {

    const handleSubmit = () => {
        props.history.push('/idverification');
    }


    return (
        <>
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-verifyAccount stretch">


                        <div className="CC-cardBox CC-rowelem-left">
                                <div className="CC-cardBox__header CC-sectionHeading">
                                    <a href="https://www.idenfy.com/" rel="noreferrer" target="_blank"><img src={iDenfyLogo} alt="iDenfyLogo"  /> </a>
                                </div>
                                <div className="CC-cardBox__body_normal">
                                    <div className="CC-text">
                                        We use a trusted third party provider iDenfy to do KYC identification for us. If you want to check more about their service, click on the logo above.
                                    </div>
                                    {/* <div className="CC-text"> */}
                                        
                                        
                                    {/* </div> */}
                                </div>
                            </div>

                            <div className="CC-cardBox CC-rowelem-right">
                                <div className="CC-cardBox__header CC-sectionHeading">
                                    ID verification
                                </div>
                                <div className="CC-cardBox__body_normal">
                                    <div className="CC-text">
                                        In the next step you will need to verify your identity by making a selfie and make photo of you goverment issued ID.
                                    </div>
                                    <div className="CC-text">
                                        If you are on your computer, we recommend that you finish the next step on your mobile by scanning QR code that will show. Do not close this window while you do it. Once your identity is verified, you will be able to proceed here.
                                    </div>
                                </div>
                                <div className="CC-cardBox__footer">
                                    <WaitButton btnclick={handleSubmit} text="Verify my identity" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default (PreIdVerify)

