import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector} from 'react-redux'
import { verifyAccount, getAccountData } from './VerifyAccountSlice'
import { fieldData } from './fieldData'
// import {getAccountverificationStatus} from '../../AccountVerification/AccountVerificationSlice'
import WaitButton from "../../../Elements/WaitButton/WaitButton";

const VerifyAccount = (props) => {
    const [errors, setErrors] = useState([])
    const dispatch = useDispatch();

    const savedFormData = useSelector((state) => state.verifyaccount.savedFormData)
    const [formData, setFormData] = React.useState(savedFormData)

    const[processing, setProcessing] = React.useState(false);


    const setValues = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })
        // if (errors.length > 0) {
        //     setErrors(errors.filter(e => e !== name))
        // }
    }
    const handleSubmit = () => {
        let myErrors = [];
        fieldData.forEach(element => {
            if (formData[element.name]==="") {
                myErrors.push(element.name);
            }
        });
        
        setErrors(myErrors);

        if (myErrors.length===0) {
            setProcessing(true)
            dispatch(verifyAccount(formData, props, () => setProcessing()))
        }
    }

    useEffect(() => {
        dispatch(getAccountData())
    },[])

    useEffect(() => {
        setFormData(savedFormData)
    },[savedFormData])


    return (
        <>
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-verifyAccount stretch">

                            <div className="CC-cardBox CC-rowelem-left">
                                <div className="CC-cardBox__header CC-sectionHeading">
                                    Thank you for starting<br></br>KYC verification process
                                </div>
                                <div className="CC-cardBox__body_normal">
                                    <div className="CC-text">
                                        Our tokens, when you buy and than stake them, will give you a part of revenue made from games played for real money. All coins that you buy now are automatically staked until 25.10.2023. Staking Payouts will be made through Paypal or to your bank account with Wire Transfer. To be able to do that, we need to verify your identity.
                                    </div>
                                    <div className="CC-text">
                                        Sure, when tokens are listed and tradeable after 25.10.2023, you will be able to buy them on decentralised market without going through our KYC procedure. But you will not be able to stake them and receive staking rewards in real money without KYC even than.
                                    </div>
                                    <div className="CC-text">
                                        So please kindly bare with us, complete the KYC, it does not take long, and become an early member of Coin Clash Community.
                                    </div>
                                </div>
                            </div>
                            <div className="CC-cardBox CC-rowelem-right">
                                <div className="CC-cardBox__header">
                                    <div className="CC-cardBox__headerTitle">Your personal data<br></br><br></br></div>
                                </div>
                                <div className="CC-cardBox__body">
                                    {fieldData.map((item) => {
                                        return (
                                            <div className="CC-form-group" key={item.id}>
                                                <div className="CC-form-control">
                                                    <input type="text" placeholder={item.placeholder} name={item.name} value={formData[item.name]} onChange={(e) => setValues(e)}  />
                                                </div>
                                                {errors.length > 0 && errors.includes(item.name) && <p className="CC-form-errorMsg">
                                                    {item.errorMsg}
                                                </p>}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="CC-cardBox__footer">
                                    <WaitButton btnclick={handleSubmit} processing={processing} text="Proceed" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = (state) => {
    return {
        errMessage: state.verifyaccount.errMessage,
        stagestatus: state.accountVerification.stagestatus,
        // successMessage: state.verifyaccount.successMessage
    }
}

export default connect(mapDispatchToProps)(VerifyAccount)

