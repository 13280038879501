



import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux';
import { getResetPassword } from './ResetPasswordSlice';




const ResetPassword = (props) => {


    const [password, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [emailError, setEmailError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [tokenRender, setTokenRender] = useState(false);
    const [tokenId, setTokenId] = useState('');
  

    const successMessage = useSelector(state => state.resetPassword.successMessage);
    const message = useSelector(state => state.resetPassword.message);

    const dispatch = useDispatch('');

    const newPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setEmailError(false);
    }
    const confirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setEmailError(false);
    }
    const onSubmit = () => {
        if (password === "" || confirmPassword === "") {
            setEmailError(true)
            setErrorMsg("Field cannot be empty")
        } else if (password.length < 9 && confirmPassword.length < 9) {
            setEmailError(true)
            setErrorMsg("password must more than 8 digits")
        } else if (password !== confirmPassword) {
            setEmailError(true)
            setErrorMsg("Password is not Equal")
        }
        else {
            dispatch(getResetPassword({ password, tokenId }), props)
            setEmailError(false)
            setErrorMsg('')
        }

    }


    useEffect(() => {
        pageReload();
    }, [])


    function pageReload() {
        if (window.location.search !== "" && window.location.search.toLowerCase().indexOf("token=") > -1) {
            var mailToken = window.location.search.slice(7)
            // console.log("saiktdvbdgbhiv",mailToken.lastIndexOf);
            let authToken = "";
            if(mailToken.endsWith(".")){
                const ind = mailToken.lastIndexOf(".")
                authToken = mailToken.slice(0, ind);
            }else{
                authToken = mailToken;
            }
            // const token = mailToken[-1] === "." ? mailToken.slice(0,-1) : mailToken;
            // console.log("mailToken", mailToken);
            // console.log("token", token);
            setTokenId(authToken);
            setTokenRender(true);

        } else {
            setTokenRender(false);
                props.history.push('/login');
        }
    }


    useEffect(() => {

        successMessage !== "" && setTimeout(()=>{props.history.push("/login")},2000);

    }, [successMessage])


    return (
        <>
            {
                tokenRender &&
                (<div className="CC-layout-content">
                    <div className="CC-section CC-innerPage">
                        <div className="CC-container">
                            <div className="CC-section__content CC-login">
                                <div className="CC-cardBox">
                                    <div className="CC-cardBox__header mb-20">
                                        <div className="CC-cardBox__headerTitle">Reset Password?</div>
                                    </div>
                                    <div className='CC-cardBox__headerInfo'>
                                        Back to login?
                                        <a href='/login'>
                                            <span className="CC-txtHighlight-secondary">  Login</span>
                                        </a>
                                    </div>
                                    <div className="CC-cardBox__body">
                                        <div className="CC-form-group">
                                            <div className="CC-form-control">
                                                <input type="email" placeholder="New Password"
                                                    value={password}
                                                    onChange={(e) => newPasswordChange(e)} />
                                            </div>
                                            <div className="CC-cardBox__headerInfo" />
                                            <br />
                                            <div className="CC-form-control">
                                                <input type="email" placeholder="Confirm Password"
                                                    value={confirmPassword}
                                                    onChange={(e) => confirmPasswordChange(e)} />
                                            </div>
                                            {emailError ? (
                                                <p className="CC-form-errorMsg">{errorMsg}</p>
                                            ) : null}
                                            {message !== "" && <p className="CC-form-errorMsg">{message}</p>}
                                            {successMessage !== "" && <div className="CC-form-successMsg">{successMessage}</div>}
                                        </div>
                                    </div>
                                    <div className="CC-cardBox__footer">
                                        <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"
                                            type="submit"
                                            onClick={() => onSubmit()}
                                        >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                )
                
            }
        </>

    )
}

export default ResetPassword

