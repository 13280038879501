import React, { useState, useEffect } from 'react'
import { getAccountverificationStatus, getAccountCoins, getAccountEarnings } from './AccountVerificationSlice';
import { useDispatch, useSelector } from 'react-redux';

import {formatNumber, formatUS} from '../Helpers/ConversionApi'


import userImg from '../../assets/images/other-icons/user.svg'
import coin from '../../assets/images/coin.png'

const AccountVerification = (props) => {


    const [status1, setStatus1] = useState(false);
    const [status2, setStatus2] = useState(false);
    const [status3, setStatus3] = useState(false);

    const statusValue = useSelector(state => state.accountVerification.statusMessage);

    const userCoins = useSelector((state) => state.accountVerification.userCoins)
    const userEarning = useSelector((state) => state.accountVerification.userEarning)

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getAccountverificationStatus())
        dispatch(getAccountCoins())
        dispatch(getAccountEarnings())
        accountStatus(statusValue);
    }, [statusValue])



    const onhandleBuyBitClash = () => {
        props.history.push("/reservebitclash")
    }

    const onhandleBuyBitClashPromo = () => {
        if (statusValue === "3") {
            props.history.push("/reservebitclash")
        } else {
            props.history.push("/verifyaccount")
        }
    }

    const accountStatus = (statusValue) => {
        if (statusValue === "1" || statusValue === "4" || statusValue === "5") {
            setStatus1(true);
            setStatus2(false);
            setStatus3(false);
        } else if (statusValue === "2") {
            setStatus2(true);
            setStatus1(false)
            setStatus3(false)
        } else if (statusValue === "3") {
            setStatus3(true);
            setStatus1(false);
            setStatus2(false);
        } else {
            setStatus1(true);
            setStatus2(false);
            setStatus3(false);
        }
    }

    const onhandleVerifyAccount = () => {
        
        props.history.push("/verifyaccount");
    }


    return (
        <>
        {status3 ? 
            <></>
            : 
            <div className="CC-superBanner CC-accountVerifyBanner">
                <div className="CC-superBanner-item CC-txt-dark1">
                    <div className="CC-container">
                        <div className="CC-superBanner-content">
                            <div className="CC-superBanner-innerContent">
                                <div className="CC-sectionHeading">

                                    {status1 ? "Verify your account" : " Your account is being verified"}

                                </div>
                                <div className="CC-sectionDesc">
                                    {status1 ? "If you wish to buy Coin Clash tokens, we need to verify your identity. This is a legal requirement because we run games where people can receive real money and once you stake your coins, you will receive real, non crypto USD."
                                        : "It might take up to 5 working days. We'll notify you once the process is done"}

                                </div>

                                {status1 ? <button className="btn CC-btnPrimary active CC-btnLarge CC-btnBanner" onClick={() => onhandleVerifyAccount()}>Verify my ID</button>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <div className="CC-superBanner CC-promo2023Banner">
                <div className="CC-superBanner-item CC-txt-dark1">
                    <div className="CC-container">
                        <div className="CC-superBanner-content">
                            <div className="CC-superBanner-innerContent">
                                <div className="CC-sectionHeading CC-20top">
                                    Get on board 
                                </div>
                                <div className="CC-sectionDescPromo">
                                    and become a part of a new exiting success story.<br/>
                                    Buy Coin Clash tokens now and get:
                                </div>

                                <div className="CC-sectionDescPromoList CC-20btm">
                                    - 10% of token buy as in-game credit in our games<br/>
                                    - A special edition NFT Coin Clash Joker.
                                </div>

                                <div className="CC-sectionDescPromo CC-40btm">
                                <button className="btn CC-btnPrimary active CC-btnLarge CC-btnBanner "
                                                onClick={() => onhandleBuyBitClashPromo()}
                                            >Buy Coin Clash Coins</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="CC-coinClash">

                <div className="CC-layout-content">
                    <div className="CC-section CC-buyCoins">
                        <div className="CC-container">
                            <div className="CC-section__content stretch">

                                <div className="CC-cardBox CC-rowelem-left">
                                    <div className="CC-cardBox__header CC-sectionHeading">
                                        <img src={userImg} alt="User" /> My Coin Clash coins
                                    </div>
                                    <div className="CC-cardBox__body">
                                        <div className="CC-coinDetails">
                                            <div className="CC-coinName">
                                                Coin Clash Coins (CCC)
                                            </div>
                                            <div className="CC-coinPrice">
                                                {formatNumber(userCoins)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="CC-cardBox__headerInfo"/>
                                    <div className="CC-cardBox__footer">
                                        {status3 &&
                                            <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnFullWidth"
                                                onClick={() => onhandleBuyBitClash()}
                                            >Buy Coin Clash Coins</button>
                                        }
                                    </div>
                                </div>


                                <div className="CC-cardBox CC-rowelem-right">
                                    <div className="CC-cardBox__header CC-sectionHeading">
                                        <img src={userImg} alt="User" /> My Earnings
                                    </div>
                                    <div className="CC-cardBox__body">
                                        <div className="CC-coinDetails">
                                            <div className="CC-coinName">
                                                USD
                                            </div>
                                            <div className="CC-coinPrice">
                                                $ {formatUS(userEarning)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="CC-cardBox__headerInfo"/>
                                    <div className="CC-cardBox__footer">
                                        <div className="CC-buttonGroup">
                                            <button  className="btn CC-btnPrimary disabled CC-btnExtraLarge CC-btnFullWidth">Stake Coins</button>
                                            <div className="CC-buttonGroupText">All of your coins are automatically staked until 25.10.2023</div>
                                        </div>

                                        {/* <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnFullWidth"
                                            onClick={() => onhandleBuyBitClash()}
                                        >Cash out</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountVerification