import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";
import { userApi } from "../../EndPointsConfig/EndPointConfig";

export const buycoinProcessingSlice = createSlice({
    name: "updateTxnHashSlice",
    initialState: {
        errMessage:"",
        successMessage: "",
    },
    reducers:{
        setMessage: (state, action) => {
            state.errMessage = action.payload;
        },
      
        setSuccessMessage: (state,action) =>{
            state.successMessage = action.payload;
        }
    }
})

export const {setMessage, setSuccessMessage} = buycoinProcessingSlice.actions;

export const updateTxnStatus =  (params, props) => (dispatch) => {
    let obj = {
        txhash:params.txhash,
        status:params.status,
    };
    console.log("dispatch is running",obj);
    let url = `${userApi.transactionFinish}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            console.log("response",response)
            if(response !== null && response) {
                if(response.status !== -1){
                    dispatch(setSuccessMessage(response.message));
                    dispatch(setMessage(""));
                   
                }else{
                    dispatch(setMessage(response.message));
                    dispatch(setSuccessMessage(""));
                }
            }
        })
        .catch((error) => dispatch(setMessage(error.error)));
}



export default buycoinProcessingSlice.reducer;



