import React, { useState, useEffect} from "react";
import { Link, useLocation } from 'react-router-dom'
import { connect, useDispatch } from "react-redux";
import { getRegisterAccess } from './RegisterSlice'
import WaitButton from "../../Elements/WaitButton/WaitButton";

const Register = (props) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [referrer, setreferrer] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    const[processing, setProcessing] = React.useState(false);

    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();

    useEffect(()=>{
        setreferrer(query.get('l'));

        console.log(query.get('l'))
    },[])

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleError = (e) => {
        setError(true);
    }

    const onSubmit = () => {
        const reg = /.+@.+\.[A-Za-z]+$/;
        if (email === "" || password === "") {
            setEmailError(true)
            setPasswordError(true)
            setErrorMsg("Fields cannot be empty")
        } else if (password.length < 8) {
            setEmailError(false)
            setPasswordError(true)
            setErrorMsg("Password should be greater than 8")
        } else if (!reg.test(email)) {
            setEmailError(true)
            setPasswordError(false)
            setErrorMsg("Please enter a valid email address")
        }
        else {
            setEmailError(false)
            setPasswordError(false)
            setProcessing(true)
            dispatch(getRegisterAccess({ email, password, referrer, handleError }, props, (a) => setProcessing(a)))
        }
    }
    return (
            <div className="CC-layout-content">
                <div className="CC-section CC-innerPage">
                    <div className="CC-container">
                        <div className="CC-section__content CC-register stretch">

                            <div className="CC-cardBox CC-rowelem-left">
                                <div className="CC-cardBox__header CC-sectionHeading">
                                    Welcome to the Coin Clash Token Portal
                                </div>
                                <div className="CC-cardBox__body_normal">
                                    <div className="CC-text">
                                        Here you are able to manage, stake and buy your Coin Clash Coins.
                                    </div>
                                    <div className="CC-text">
                                        If you have an account to play games on Coin Clash Games, it will not work here. Please create a new account to buy and manage your coins.
                                    </div>
                                </div>
                            </div>
                            <div className="CC-cardBox CC-rowelem-right">
                                <div className="CC-cardBox__header">
                                    <div className="CC-cardBox__headerTitle">Create An Account</div>
                                </div>
                                <Link to="/login">
                                    <div className="CC-cardBox__headerInfo">
                                        Already an user? <span className="CC-txtHighlight-secondary">Sign
                                            In</span>
                                    </div>
                                </Link>
                                <div className="CC-cardBox__body">
                                    <div className="CC-form-group">
                                        <div className="CC-form-control">
                                            <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => handleEmail(e)} />
                                        </div>
                                        {emailError && (
                                            <p className="CC-form-errorMsg">{errorMsg}</p>
                                        )}
                                    </div>

                                    <div className="CC-form-group">
                                        <div className="CC-form-control"><input type="password" placeholder="Password" value={password} onChange={(e) => handlePassword(e)} /></div>
                                        {passwordError && (
                                            <p className="CC-form-errorMsg">{errorMsg}</p>
                                        )}
                                    </div>
                                    <div className="CC-form-group">
                                        
                                    {error ? (
                                            <p className="CC-form-errorMsg">{props.message}</p>
                                        ): null}
                                    </div>
                                </div>
                                <div className="CC-cardBox__footer">
                                    <WaitButton btnclick={onSubmit} processing={processing} text="Register" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => {
    return{
        userData: state.register.userData,
        message: state.register.message,
    }
}

export default connect(mapStateToProps)(Register);

