import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAmountOfBitclashAccess, reserveBitClash } from './idVerificationSlice'

const IdVerification = (props) => {

  // const handleProcess = () =>{
  //   localStorage.setItem("accountVerified", true)
  //   props.history.push("/finishedmobilenumber")
  // }

  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = parseJwt(token);

  const userId = decodedJwt ? decodedJwt.id : Math.floor(Math.random() * 10000);

  const idenfyUrl = "https://ui.idenfy.com/";

  const [iframSrc, setIframeUrl] = useState(idenfyUrl);

  React.useEffect(() => {
      // POST request using fetch inside useEffect React hook
      const requestOptions = {
          method: 'POST',
          // headers: { 'Authorization': 'Basic SXZEaEJIZkFIdVg6WjNyTU1rR21JOTJPbkNCSzVuMWQ=' }, // test
          headers: { 'Authorization': 'Basic SmY1UWJUSUdYWXo6YnVsZm40WENWb2FKWm9Vanc2dGM=' }, // production
          body: JSON.stringify({ 
            clientId: decodedJwt.id,
          })
      };
      fetch('https://ivs.idenfy.com/api/v2/token', requestOptions)
          .then(response => response.json())
          .then(data => {
            console.log(data);
            console.log(data.message);
            console.log(data.authToken);

            if (data.authToken) {
              setIframeUrl(
                `${idenfyUrl}?authToken=${data.authToken}`
              );
            }
          });

  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      console.log(event);
      console.log(event.data);

      if (event.data.status) {
        if (event.data.status.toUpperCase() === 'FAILED' || event.data.status.toUpperCase() === 'UNVERIFIED') {
          console.log("show message that it needs additional data")
          props.history.push("/verificationfinish");
        }
  
        if (event.data.status.toUpperCase() === 'APPROVED') {
          props.history.push("/finishedmobilenumber");
        }
      }

    });
  });

  return (
    <>
        <div className="CC-layout-content">
          <div className="CC-section CC-innerPage CC-idverificationMain">
            <div className="CC-container">
              <div className="CC-section__header">User verification with iDenfy</div>
              <div className="CC-section__content CC-IDverification">

                <div className="CC-cardBox">
                  <iframe 
                      title="idenfy"
                      allowFullScreen
                      style={{width:"100%", height:"100%"}}
                      src={iframSrc}
                      allow="camera"
                    ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default IdVerification