import { createSlice } from "@reduxjs/toolkit";
import {apiCaller} from "../../../Utils/apiCaller";
import { verifyAccountUserData } from "../../EndPointsConfig/EndPointConfig";


export const VerifyAccountSlice = createSlice({
    name: "verifyAccountSlice",
    initialState: {
        accountVerified:false,
        errMessage:"",
        successMessage: "",
        savedFormData: {
            firstName: "",
            lastName: "",
            address: "",
            zipCode: "",
            country: "",
            province: "",
        }
    },
    reducers:{
        setMessage: (state, action) => {
            state.errMessage = action.payload;
        },
        setAccountVerified: (state, action) => {
            state.accountVerified = action.payload;
        },
        setSuccessMessage: (state,action) =>{
            state.successMessage = action.payload;
        },
        setFormData: (state,action) =>{
            state.savedFormData = action.payload;
        }
    }
})

export const {setMessage, setAccountVerified, setSuccessMessage, setFormData} = VerifyAccountSlice.actions;

export const verifyAccount =  (params, props, processing) => (dispatch) => {
    let obj = {
        province:params.province,
        address:params.address,
        country:params.country,
        firstName: params.firstName,
        lastName: params.lastName,
        zipCode: params.zipCode,
    };

    let url = `${verifyAccountUserData.verifyUserData}`;
    apiCaller(url, "POST", obj)
        .then((response) => {
            processing(false);
            if(response !== null && response) {
                if(response.status !== -1){
                    dispatch(setAccountVerified(response.accountVerified));
                    dispatch(setSuccessMessage(response.message));
                    dispatch(setMessage(""));
                    props.history.push('/preidverify');
                   
                }else{
                    dispatch(setMessage(response.message));
                    dispatch(setSuccessMessage(""));
                }
            }
        })
        .catch((error) => {
            dispatch(setMessage(error.error));
            processing(false);
        });
}

export const getAccountData =  () => (dispatch) => {
    
    let url = `${verifyAccountUserData.getAccountUserData}`;
    apiCaller(url, "GET", {})
    .then((response) => {
        if (response !== null && response) {
          if (response.status === 1) {
            let obj = {
                province:response.province,
                address:response.address,
                country:response.country,
                firstName: response.first_name,
                lastName: response.last_name,
                zipCode: response.zip_code,
            };
            dispatch(setFormData(obj))
          } else {
            dispatch(setMessage(response.message));
          }
        }
      })
      .catch((error) => dispatch(setMessage(error.message)));
}

export default VerifyAccountSlice.reducer;