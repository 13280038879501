import React from 'react'
import LineChart from '../Chart/Chart'
import {bitclashToUstc} from '../../components/Helpers/ConversionApi'
import { useState } from 'react'


function Dashboard(props) {


    const [active, setActive] = useState('12 hrs')
    const names = ['12 hrs', '24 hrs', '1 week', '1 month', 'All'];

    const handleActiveclass = (val) => {
        setActive(val);
    }

    return (
        <>
                <div className="CC-section CC-dashboard">
                    <div className="CC-container">
                        <div className="CC-tabs">
                            <ul className="nav">
                                <li className="">
                                    <div className="CC-tab-item active">Coin Clash coins</div>
                                </li>
                            </ul>
                        </div>
                        <div className="CC-tab-content">
                            <div className="CC-tab-pane CC-home-upcoming active" id="tab1">
                                <div className="CC-section__content">
                                    <div className="CC-section__contentLeft">
                                        <div className="CC-sectionHeading">Dashboard</div>
                                        <div className="CC-sectionDesc">Total earning in USD for all Coin Clash token holders that
                                            staked their coins</div>
                                        <div className="CC-tabs-pills">
                                            <ul>
                                                {names.map((val, index) => (
                                                    <li
                                                        key={index}>
                                                        <a className={active === val ? "CC-tab-item active" : "CC-tab-item inactive"} data-toggle="tab" href="#tab1" onClick={() => handleActiveclass(val)}>
                                                            {val}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>

                                        </div>
                                        <div className="CC-coinDetails">
                                            <div className="CC-coinName">
                                                <img
                                                    src="https://html.sportsit-tech.net/coinclash/images/coin.png"
                                                    alt="Coin"
                                                />
                                                Coin Clash
                                            </div>
                                            <div className="CC-coinPrice">
                                               {props.amount ? bitclashToUstc(props.amount) : 0}
                                            </div>
                                        </div>
                                    </div>
                                    <LineChart type={active} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Dashboard
