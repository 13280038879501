import React from 'react'
// import successIcon from '../../../assets/images/other-icons/barcode.svg';
import closeIcon from '../../../assets/images/other-icons/close.svg';
import { connect, useDispatch } from 'react-redux'
import { buySuccess } from './BuyCoinSuccessSlice';

const BuyCoinsSuccess = (props) => {
    const dispatch = useDispatch();
    const handleBuyMore = () =>{
        dispatch(buySuccess(props.amount, props));
    }
    React.useEffect(() => {
        props.handleBuySucess()
    },[])
  return (
    <>
        <div className="CC-layout-content">
            <div className="CC-section CC-innerPage">
                <div className="CC-container">
                    <div className="CC-section__content CC-success">
                        <div className="CC-cardBox">
                            <div className="CC-cardBox__header">
                                <div className="CC-cardBox__headerTitle">Success</div>
                                <div className="CC-cardBoxClose">
                                    <div onClick={() => props.history.push("/dashboard")}><img alt="" src={closeIcon} /></div>
                                </div>
                            </div>
                            <div className="CC-cardBox__body">
                                <div className="CC-cardBox__bodyDesc">You transaction was succesfull and your acccount has been credited with Coin Clash Coins.</div>
                                {/* <div className="CC-successImg">
                                    <img src={successIcon} alt="Email Verification" />
                                </div> */}
                                <div className="CC-space20"></div>
                            </div>
                            <div className="CC-cardBox__footer">
                                <button className="btn CC-btnPrimary active CC-btnExtraLarge CC-btnCardBox"  onClick={() => handleBuyMore() }
                                >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BuyCoinsSuccess
